<template>
	<div class="base-header is-fixed-top">
		<div class="header-left">
			<router-link :to="{ name: 'Home' }" class="header-item logo is-unselectable">
				<img src="@/assets/logo-full-white.svg" />
			</router-link>

			<router-link :to="{ name: 'NavigatorBase' }" class="header-item">
				<span class="icon">
					<icon icon="location-pin" />
				</span>
				<span>Navigator</span>
			</router-link>
		</div>
		<div class="header-right">
			<slot />
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
$header-height: $navigator-header-height;
$header-min-height: $navigator-header-height-min;
$gap: 0.5rem;

.base-header {
	position: relative;
	z-index: 5;
	height: $navigator-header-height;
	max-height: $navigator-header-height;
	min-height: $navigator-header-height;
	width: 100%;
	padding: 0 $gap;
	background-color: $base-background-color;
	border-bottom: 1px solid $base-hover-background-color;
	overflow-x: hidden;

	display: flex;
	align-items: center;
	gap: $gap;
	justify-content: space-between;

	.header-left {
		height: 100%;

		display: flex;
		gap: $gap;

		.header-item {
			height: 100%;

			padding: 0 $gap;

			display: flex;
			align-items: center;
			justify-content: center;
			color: $base-hover-text-color;
			font-weight: 500;

			&:hover {
				background-color: $base-hover-background-color;
				color: $base-active-text-color;
				cursor: pointer;
			}
		}
	}
}

.logo {
	padding: 0 1rem;
	height: inherit;
	display: flex;
	align-items: center;
	height: 100%;

	&:hover {
		background-color: $base-hover-background-color;
		cursor: pointer;
	}

	img {
		max-height: calc($navigator-header-height-min - 8px);
	}
}
</style>
