<template>
	<section class="welcome-sheet">
		<div class="welcome-sheet-wrapper has-shadow" v-if="!pathID">
			<div class="welcome-sheet-item">
				<div class="item-header" @click.prevent="setNavigatorData(refID)">
					<div class="item-title is-unselectable">Create new layer</div>
				</div>
			</div>
			<div class="welcome-sheet-item is-expandable">
				<div class="item-header" @click.prevent="toggleImportLayer">
					<div class="item-title is-unselectable">Load my layers</div>
					<div class="icon" @click.stop="toggleImportLayer">
						<icon icon="chevron-down" :class="isImportLayerExpanded ? 'fa-flip-vertical' : ''" />
					</div>
				</div>
				<div class="item-body" v-if="isImportLayerExpanded">
					<ImportLayer :refID="refID" />
				</div>
			</div>
			<div class="welcome-sheet-item is-expandable">
				<div class="item-header" @click.prevent="toggleSharedLayer">
					<div class="item-title is-unselectable">Load shared layers</div>
					<div class="icon" @click.stop="toggleSharedLayer">
						<icon icon="chevron-down" :class="isSharedLayerExpanded ? 'fa-flip-vertical' : ''" />
					</div>
				</div>
				<div class="item-body" v-if="isSharedLayerExpanded">
					<SharedLayer :refID="refID" />
				</div>
			</div>
			<div class="welcome-sheet-item is-expandable">
				<div class="item-header">
					<div class="item-title is-unselectable" @click.prevent="toggleMergeLayers">Merge Layers</div>
					<div class="icon" @click.stop="toggleMergeLayers">
						<icon icon="chevron-down" :class="isMergeLayerExpanded ? 'fa-flip-vertical' : ''" />
					</div>
				</div>
				<div class="item-body" v-if="isMergeLayerExpanded">
					<MergeLayers :refID="refID" />
				</div>
			</div>
		</div>

		<div class="welcome-sheet-wrapper has-shadow fade-in-up" v-if="pathID">
			<div class="welcome-sheet-item is-unselectable">
				<div class="item-header">
					<div class="item-title is-unselectable">
						<span class="loader is-dark"></span>
						<span>Fetching Layer details</span>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from "axios"
import ImportLayer from "@/components/ImportLayer.vue"
import MergeLayers from "@/components/MergeLayers.vue"
import SharedLayer from "./SharedLayer.vue"
import { mapActions, mapGetters } from "vuex"

export default {
	components: {
		ImportLayer,
		MergeLayers,
		SharedLayer
	},
	data() {
		return {
			isImportLayerExpanded: false,
			isSharedLayerExpanded: false,
			isMergeLayerExpanded: false,
			pathID: null
		}
	},
	props: {
		refID: {
			type: [String]
		}
	},
	methods: {
		...mapActions(["setNavigatorData"]),
		...mapActions([
			"setCustomNavigatorData",
			"selectSingleTechnique",
			"selectMultipleSubtechnique",
			"createNewLayer",
			"setShowSelectedItems"
		]),
		toggleImportLayer() {
			this.isImportLayerExpanded = !this.isImportLayerExpanded
		},
		toggleSharedLayer() {
			this.isSharedLayerExpanded = !this.isSharedLayerExpanded
		},
		toggleMergeLayers() {
			this.isMergeLayerExpanded = !this.isMergeLayerExpanded
		},
		parsePathIDFromURL() {
			let len = this.tabConfig.length
			let { unsetPathID } = this.tabConfig[len - 1]
			if (unsetPathID) {
				return
			} else {
				let { pathID } = this.$route.params
				if (!pathID) return
				this.pathID = pathID

				let url = `/user/path/${pathID}`
				axios.get(url).then(response => {
					if (!response.data.success) return
					let layer = response.data

					if (!Object.keys(layer).length) return
					console.log("tabConfig:: ", JSON.parse(JSON.stringify(this.tabConfig)))

					this.$router.replace({ params: { pathID: null } })
					this.createNewLayer(layer.title).then(r => {
						let tabID = r

						let payload = {
							tabID: tabID,
							data: layer.selectedItems,
							active: true,
							title: layer.title,
							oldTabID: this.tabConfig[0].id
						}
						this.processCustomNavigatorData(payload)
					})
				})
			}
		},
		processCustomNavigatorData(payload) {
			this.setCustomNavigatorData(payload).then(() => this.selectPath(payload))
		},
		selectPath(selection) {
			let { resolveTacticID, resolveTechniqueID, procesSubtechniques } = this
			let pathArr = selection.data
			let refID = this.tabConfig[0].id

			pathArr.forEach(path => {
				let tacticID = path.tid
				let techniqueID = path.tnid
				let subtechniques = path.stnid

				let tid = resolveTacticID(tacticID)
				let tnid = resolveTechniqueID(tacticID, techniqueID, subtechniques)

				let payload = { refID, tid, tnid }

				this.selectSingleTechnique(payload).then(() => {
					if (subtechniques?.length) {
						procesSubtechniques({ subtechniques, ...payload })
					}
				})
			})
		},
		resolveTacticID(tacticID) {
			let tid = parseInt(tacticID) - 1
			return tid
		},
		resolveTechniqueID(tacticID, techniqueID) {
			let { navigatorData, resolveTacticID } = this
			let tid = resolveTacticID(tacticID)
			let tnid
			let refID = this.tabConfig[0].id

			// navigatorData[refID][tid].techniques.findIndex(technique => {
			// })
			tnid = navigatorData[refID][tid].techniques.findIndex(technique => technique.id == techniqueID)

			return tnid
		},
		procesSubtechniques({ subtechniques, tid, tnid }) {
			let { selectMultipleSubtechnique, resolveSubtechniqueID } = this
			let refID = this.tabConfig[0].id

			subtechniques.forEach(subtechniqueID => {
				let stnid = resolveSubtechniqueID({ subtechniqueID })
				selectMultipleSubtechnique({ refID, tid, tnid, stnid })
			})
		},
		resolveSubtechniqueID({ subtechniqueID }) {
			let stnid = parseInt(subtechniqueID) - 1
			return stnid
		},
		processShowSelectedItems() {
			let { pathID } = this.$route.params
			if (!pathID) return

			this.setShowSelectedItems(true)
		}
	},
	mounted() {
		this.parsePathIDFromURL() // this will set the refID
		this.processShowSelectedItems()
	},
	computed: {
		...mapGetters(["activeTab", "tabConfig", "navigatorData"])
	}
}
</script>

<style lang="scss" scoped>
.welcome-sheet {
	display: flex;
	flex-direction: column;

	background-color: white;

	width: 100%;
	height: 100%;
	padding: calc($gap * 2);
	align-items: center;
	overflow: auto;

	.welcome-sheet-item:not(:last-child) {
		border-bottom: 1px solid $base-background-color-light;
	}

	.welcome-sheet-wrapper {
		width: 100%;
		max-width: 768px;
		border-radius: $gap;
		height: fit-content;

		.welcome-sheet-item {
			width: 100%;
			min-height: 50px;
			padding: calc($gap * 1.5);
			background-color: inherit;
			color: $base-text-color-light;

			.item-header {
				display: flex;
				gap: $gap;
				align-items: center;
				.item-title {
					display: flex;
					align-items: center;
					gap: $gap;
					font-size: 14px;
					width: 100%;
					border-radius: calc($gap/2);
					color: inherit;
					font-weight: inherit;
					padding: $gap;
					cursor: pointer;
					&:hover {
						background-color: $base-hover-background-color-light;
					}

					&.is-unselectable {
						user-select: none;
						&:hover {
							background-color: transparent;
						}
					}
				}

				.icon {
					cursor: pointer;
				}
			}

			.item-body {
				height: fit-content;
				width: 100%;
				padding: $gap;
				background-color: $base-background-color-light;
				border-radius: calc($gap/2);
				overflow: auto;
			}
		}
		.welcome-sheet-item.is-expandable {
			display: flex;
			flex-direction: column;
			gap: $gap;
			.item-header {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.icon {
					border-radius: 100px;
					padding: $gap;
					height: 2rem;
					width: 2rem;
					&:hover {
						background-color: $base-hover-background-color-light;
					}
				}
			}
		}

		&.has-shadow {
			box-shadow: 0 0 10px 1px hsla(0deg, 0%, 0%, 0.1);
		}
	}
}

.item-title .tag {
	font-size: 10px !important;
	opacity: 0.5;
}
</style>
