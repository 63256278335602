<template>
	<div class="welcome-sheet-body">
		<!-- show info message to user -->
		<div class="welcome-sheet-item dimmed" v-if="selectedItems.length < 3">
			Please add minimum 3 keywords to get suggestions...
		</div>

		<!-- No results -->
		<div class="welcome-sheet-item dimmed" v-else-if="!suggestions?.length">No results found</div>

		<!-- Show results / tactics data -->
		<div class="welcome-sheet-item" v-else>
			<div class="welcome-sheet-header">
				<div class="header-item">
					<span
						>Suggested Crime Paths
						<span class="counter">({{ Object.keys(suggestions).length }})</span>
					</span>
				</div>
				<div class="header-item spinner fade-in-right" v-if="fetchingSuggestions">
					<span class="loader is-dark"></span>
					<span class="dimmed">fetching...</span>
				</div>
			</div>
			<div class="suggestions">
				<div
					class="suggestion-item"
					v-for="(tactic, tactic_index) in suggestions"
					:key="tactic_index"
					:class="[{ 'is-selected': selectedSuggestion === tactic_index }]"
					@click="setSelectedSuggestion({ tactic_index })"
				>
					<div class="suggestion-head">
						<div class="suggestion-head-item">
							<div class="suggestion-head-item-title">{{ tactic.title }}</div>
							<div class="suggestion-head-item-subtitle" v-if="tactic.desc">{{ tactic.desc }}</div>
						</div>
						<div class="suggestion-head-item">
							<!-- Count of crime paths -->
							<div class="suggestion-head-item-title counter">{{ suggestions[tactic_index].path?.length }} path(s)</div>
						</div>
					</div>

					<div class="suggestion-body" v-if="tactic.path?.length">
						<div
							class="suggestion-body-item"
							:class="[{ 'is-selected': selectedPath === path }]"
							v-for="(path, path_index) in tactic.path"
							:key="path_index"
							@click="setSelectedPath({ tactic_index, path_index })"
						>
							<div class="suggestion-body-item-title">
								{{ path.title }}
							</div>
							<div class="suggestion-body-item-subtitle" v-if="path.desc">{{ path.desc }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<WelcomeSheetTacticDetails @set-tab-config="setTabConfig($event)" />

	<!-- Full screen preview path  -->
	<WelcomeSheetPreviewPath v-if="isPreviewVisible" :tab-config="previewTabConfig" />
</template>

<script>
import WelcomeSheetTacticDetails from "@/components/WelcomeSheet/WelcomeSheetTacticDetails.vue"
import WelcomeSheetPreviewPath from "@/components/WelcomeSheet/WelcomeSheetPreviewPath.vue"
import { mapActions, mapGetters } from "vuex"

export default {
	data() {
		return {
			previewTabConfig: {}
		}
	},
	components: {
		WelcomeSheetTacticDetails,
		WelcomeSheetPreviewPath
	},
	methods: {
		...mapActions("welcomeSheet", ["setSelectedSuggestion", "setSelectedPath", "previewSelectedPath"]),
		...mapActions(["setCustomNavigatorData", "selectSingleTechnique", "selectMultipleSubtechnique"]),

		setTabConfig(tabConfig) {
			this.previewTabConfig = tabConfig

			// set custom navigatorData
			let payload = { tabID: tabConfig.id, oldTabID: tabConfig.id, title: tabConfig.title }

			this.setCustomNavigatorData(payload).then(() => {
				this.selectPath({ ...payload, data: JSON.parse(JSON.stringify(this.selectedPath.path)) })
				this.$nextTick(() => this.previewSelectedPath())
			})
		},
		selectPath(pathItem) {
			let { parseTacticIndex, parseTechniqueIndex, procesSubtechniques } = this
			let pathArr = pathItem.data
			let { tabID } = pathItem

			if (!pathArr?.length) return

			pathArr.forEach(path => {
				let { tid, tnid, stnid, color } = path
				let tactic_index = parseTacticIndex(tid)
				let technique_index = parseTechniqueIndex(tabID, tactic_index, tnid)
				let payload = { refID: tabID, tid: tactic_index, tnid: technique_index, stnid, color }

				this.selectSingleTechnique({ ...payload })
					.then(() => {
						if (stnid.length >= 0) procesSubtechniques({ ...payload })
					})
					.catch(err => {
						console.log("selectSingleTechnique:: FAILURE", err)
					})
			})
		},
		procesSubtechniques({ refID, tid, tnid, stnid }) {
			let { selectMultipleSubtechnique } = this
			selectMultipleSubtechnique({ refID, tid, tnid, stnid })
		},
		parseTacticIndex(tacticID) {
			let tactic_index = parseInt(tacticID) - 1
			return tactic_index
		},
		parseTechniqueIndex(refID, tactic_index, techniqueID) {
			let techniques = this.navigatorData[refID][tactic_index].techniques
			let index

			index = techniques.findIndex(technique => technique.id === techniqueID)

			return index
		}
	},
	computed: {
		...mapGetters("welcomeSheet", [
			"selectedItems",
			"suggestions",
			"selectedSuggestion",
			"selectedPath",
			"isPreviewVisible",
			"fetchingSuggestions"
		]),
		...mapGetters(["navigatorData"])
	}
}
</script>

<style lang="scss" scoped>
.welcome-sheet-body {
	position: relative;
	flex: 1;
	background-color: inherit;
	z-index: 1;
	height: 100%;
	gap: calc($gap * 2);
	overflow-y: auto;

	.welcome-sheet-item {
		.welcome-sheet-header {
			background-color: unset;
			display: flex;
			gap: $gap;
			justify-content: space-between;
			align-items: center;
			font-weight: 600;
			font-size: 1.1rem;
			color: $base-text-color-light;

			position: sticky;
			top: 0;

			background-color: white;
			padding: $gap 0;
			border-bottom: 1px solid $base-hover-background-color;
			margin-bottom: calc($gap * 1.5);

			.counter {
				color: $base-text-color;
			}

			.header-item {
				&.spinner {
					display: flex;
					gap: calc($gap/2);
					font-size: 0.8rem;
					align-items: center;
					color: $base-text-color-light;
				}
			}
		}
		.suggestions {
			display: flex;
			flex-direction: column;
			gap: $gap;

			.suggestion-item {
				background-color: hsl(0, 0%, 97%);
				border-radius: $gap;
				padding: calc($gap * 2);
				display: flex;
				flex-direction: column;
				gap: $gap;

				.suggestion-head {
					display: flex;
					justify-content: space-between;
					gap: $gap;
					align-items: center;
					.suggestion-head-item {
						.suggestion-head-item-title {
							font-size: 16px;
							font-weight: 600;
							color: $base-text-color;

							&.counter {
								font-size: 12px;
								color: hsl(0, 0%, 60%);
								font-weight: 500;
							}
						}

						.suggestion-head-item-subtitle {
							font-size: 12px;
							font-weight: 600;
							color: $base-text-color;
						}
					}
				}

				.suggestion-body {
					display: flex;
					flex-wrap: wrap;
					gap: $gap;

					.suggestion-body-item {
						display: flex;
						flex-direction: column;
						gap: calc($gap/2);

						background-color: hsl(0, 0%, 93%);
						border-radius: calc($gap/2);
						padding: $gap;
						font-size: 14px;
						color: hsl(0, 0%, 40%);
						border: 2px solid transparent;
						// transition: border-color 150ms ease-in-out;

						&.is-selected {
							border-color: $base-text-color-light;
						}

						&:hover {
							cursor: pointer;
							background-color: hsl(0, 0%, 90%);
							color: hsl(0, 0%, 30%);
						}
					}
				}
			}
		}
	}
}

.dimmed {
	color: $base-text-color-light;
}
</style>
