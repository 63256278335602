<template>
	<div>
		{{ handle }}
	</div>
</template>

<script>
export default {
	props: {
		handle: {
			type: String,
			required: true
		}
	}
}
</script>

<style lang="scss" scoped></style>
