<template>
	<div class="navigator">
		<img src="@/assets/logo.svg" alt="" />
		<p class="title is-4 mb-2">Cybercrime Navigator</p>
		<LoginBase />

		<div class="funding">
			<p>Funded by</p>
			<span class="logo">
				Microsoft
				<img
					src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
					height="24"
					width="24"
					alt=""
				/>
			</span>
		</div>
	</div>
</template>

<script>
import LoginBase from "@/components/LoginBase.vue"
import { mapGetters } from "vuex"

export default {
	name: "Home",
	components: {
		LoginBase
	},
	computed: {
		...mapGetters(["isLoggedIn"])
	}
}
</script>

<style lang="scss" scoped>
.navigator {
	display: flex;
	gap: $gap;
	text-align: center;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: calc($gap * 10);
	height: 100%;
	max-width: 450px;
	padding: calc($gap * 2);
	margin: auto;

	.funding {
		display: flex;
		gap: calc($gap/2);
		align-items: center;
		justify-content: center;

		width: 300px;
		color: $base-hover-text-color;
		font-size: 12px;

		img {
			max-width: 80px;
		}

		.logo {
			display: flex;
			gap: calc($gap/2);
			align-items: center;
			justify-content: center;
			font-weight: 500;
		}
	}
}

.button {
	height: fit-content;
	cursor: pointer;
	background-color: $base-hover-background-color;
	color: $base-hover-text-color;
	border: 0;
	padding: 1rem;
	text-decoration: none;

	&:hover {
		background-color: $base-active-background-color-light;
		color: $base-hover-text-color-light;
	}
}
</style>
