export default {
	methods: {
		epochToDate(epoch) {
			// Get the user's timezone offset in minutes
			// var offsetInMinutes = new Date().getTimezoneOffset()

			// Convert the Unix Epoch time (in seconds) to milliseconds
			var timestamp = parseInt(epoch)

			// Create a new Date object with the adjusted timestamp
			var date = new Date(timestamp)

			// Format the date, time, and day of the week as strings using the user's locale
			var dateFormatOptions = { year: "numeric", month: "long", day: "numeric" }
			var dateFormatted = date.toLocaleDateString(undefined, dateFormatOptions)

			var timeFormatOptions = { hour: "numeric", minute: "numeric" }
			var timeFormatted = date.toLocaleTimeString(undefined, timeFormatOptions)

			var dayFormatted = date.toLocaleDateString(undefined, { weekday: "long" })

			return { date: dateFormatted, time: timeFormatted, day: dayFormatted }
		}
	}
}
