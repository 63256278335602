import CONSTANTS from "@/assets/js/constants.js"

const initialState = () => {
	return {
		tabConfig: [],
		// navigatorData: JSON.parse(localStorage.getItem("navigatorData")) || {},
		navigatorData: {},
		selectedItems: {},
		activeTab: null,
		showSelectedItems: false,
		baseTabConfig: CONSTANTS.baseTabConfig,
		isMergingLayers: false,
		isSidebarOpen: false,
		isTechniqueEditable: 1, // preview
		selectedTechniqueInfo: {},
		tacticColorScheme: {
			background_color: "",
			color: ""
		},
		isLoggedIn: false || !!localStorage.getItem("isLoggedIn"),
		accessToken: null || localStorage.getItem("accessToken"),
		sops: [
			{
				sopTitle: "Mobile Number",
				createdBy: "Admin",
				createdAt: "2021-09-01",
				id: "1",
				actions: [
					{
						type: "router-link",
						route: "SOPsView",
						key: "SOPsView",
						paramId: "SOPID",
						id: "1",
						title: "view",
						icon: "eye"
					}
				]
			},
			{
				id: "2",
				sopTitle: "Email Address",
				createdBy: "Admin",
				createdAt: "2021-09-01",
				actions: [
					{
						type: "router-link",
						route: "SOPsView",
						key: "SOPsView",
						paramId: "SOPID",
						id: "2",
						title: "view",
						icon: "eye"
					}
				]
			},
			{
				sopTitle: "IP Address",
				createdBy: "Admin",
				createdAt: "2021-09-01",
				id: "3",
				actions: [
					{
						type: "router-link",
						route: "SOPsView",
						key: "SOPsView",
						paramId: "SOPID",
						id: "3",
						title: "view",
						icon: "eye"
					}
				]
			}
		]
	}
}

export default initialState
