<template>
	<div class="selected-items">
		<div class="selected-items-head">
			<p class="caption is-uppercase has-text-grey has-text-weight-bold is-size-7">
				Selected items
				<span class="has-text-grey-light has-text-weight-bold" v-if="selectedItems?.length"
					>({{ selectedItems?.length }})</span
				>
			</p>
			<div class="reset-wrapper">
				<p class="reset-selected-items" @click="processResetAllSelectedItems">Clear</p>
			</div>
		</div>

		<div class="selected-items-body">
			<div class="field is-grouped is-grouped-multiline">
				<div class="control" v-for="(item, index) in selectedItems" :key="index">
					<div class="tags has-addons">
						<div class="tag is-light is-medium">{{ item }}</div>
						<a class="tag is-delete is-medium" @click="processRemoveSelectedItem(item)"></a>
					</div>
				</div>
			</div>
		</div>

		<!-- footer  -->
		<!-- <div class="selected-items-foot mt-4">		</div> -->
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
export default {
	data() {
		return {}
	},
	methods: {
		...mapActions("welcomeSheet", ["removeSelectedItem", "resetAllSelectedItems", "fetchSuggestions"]),
		processRemoveSelectedItem(index) {
			this.removeSelectedItem(index).then(() => this.$emit("remove-item"))
		},
		processResetAllSelectedItems() {
			this.resetAllSelectedItems().then(() => this.$emit("remove-item"))
		}
	},
	computed: {
		...mapGetters("welcomeSheet", ["selectedItems"])
	},
	updated() {
		this.fetchSuggestions()
	}
}
</script>

<style lang="scss" scoped>
.selected-items {
	display: flex;
	flex-direction: column;
	gap: $gap;

	overflow-y: auto;

	.selected-items-head {
		display: flex;
		gap: $gap;
		justify-content: space-between;

		.reset-wrapper {
			display: flex;
			flex-wrap: nowrap;
			gap: $gap;
			.reset-selected-items {
				color: hsl(0, 0%, 60%);
				&:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}
	}
}

.tags.has-addons {
	.tag:first-child {
		border-right: 1px solid hsl(0, 0%, 80%) !important;
	}

	.tag:last-child {
		cursor: pointer;
	}
}
</style>
