// WelcomeSheet Vuex store

import axios from "axios"
import validationData from "@/assets/validationData.json" // TO BE REMOVED

const root = { root: true }

const initialState = () => {
	return {
		selectedItems: new Set(),
		suggestions: [],
		selectedSuggestion: {},
		selectedPath: {},
		validationData: validationData,
		isPreviewVisible: false,
		fetchingSuggestions: false
	}
}

export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		// ADD / SET
		ADD_KEYWORD(state, keyword) {
			state.selectedItems.add(keyword)
		},
		SET_SUGGESTIONS(state, suggestions) {
			state.suggestions = suggestions
		},
		SET_SELECTED_SUGGESTION(state, suggestion) {
			state.selectedSuggestion = suggestion
		},
		SET_SELECTED_PATH(state, { tactic_index, path_index }) {
			state.selectedPath = state.suggestions[tactic_index].path[path_index]
		},
		SET_SUGGESTIONS_STATE(state, value) {
			state.fetchingSuggestions = value
		},

		// TOGGLE
		TOGGLE_PREVIEW_PATH_VISIBILITY(state, value) {
			state.isPreviewVisible = value
		},

		// REMOVE
		REMOVE_SELECTED_ITEM(state, index) {
			state.selectedItems.delete(index)
		},

		// RESET
		RESET_ALL_SELECTED_ITEMS(state) {
			state.selectedItems = []
		},
		RESET_SELECTED_PATH(state) {
			state.selectedPath = {}
		},
		RESET_SELECTED_SUGGESTION(state) {
			state.selectedSuggestion = {}
		},
		RESET_SUGGESTIONS(state) {
			state.suggestions = []
		},
		RESET_STATE(state) {
			const newState = initialState()
			Object.keys(newState).forEach(key => {
				state[key] = newState[key]
			})
		}

	},
	actions: {
		addKeyword({ commit }, keyword) {
			commit('ADD_KEYWORD', keyword)
		},
		removeSelectedItem({ commit }, index) {
			commit('REMOVE_SELECTED_ITEM', index)
		},
		resetAllSelectedItems({ commit }) {
			commit('RESET_ALL_SELECTED_ITEMS')
		},
		fetchSuggestions({ commit, getters }) {
			// reset selectedPath
			commit('RESET_SELECTED_PATH')
			//reset selectedSuggestion
			commit('RESET_SELECTED_SUGGESTION')
			// set fetchingSuggestions state
			commit('SET_SUGGESTIONS_STATE', true)

			let selectedItems = Array.from(getters.selectedItems)

			let { length } = selectedItems

			if (length < 3) return false

			let url = 'user/navigation/search'

			let payload = { searchItems: selectedItems }

			axios.post(url, payload).then(response => {
				// save to suggestions
				commit('SET_SUGGESTIONS', response.data.result)
				// reset fetchingSuggestions state
				commit('SET_SUGGESTIONS_STATE', false)
			}).catch(error => {
				console.log("error::", error)
				// reset fetchingSuggestions state
				commit('SET_SUGGESTIONS_STATE', false)
			})
		},
		setSelectedSuggestion({ commit }, suggestion) {
			commit('SET_SELECTED_SUGGESTION', suggestion)
		},
		setSelectedPath({ commit, getters }, { tactic_index, path_index }) {
			// remove if already selected
			if (getters.selectedPath === getters.suggestions[tactic_index].path[path_index]) commit('RESET_SELECTED_PATH')
			else commit('SET_SELECTED_PATH', { tactic_index, path_index })
		},
		previewSelectedPath({ commit, getters }, value = true) {
			let { isPreviewVisible } = getters
			if (isPreviewVisible) {
				// reset tabConfig on root store
				commit("RESET_TAB_CONFIG", null, root)

				// reset NavigatorData
				commit("RESET_NAVIGATOR_DATA", null, root)

				// reset selectedItems on root store
				commit("RESET_ALL_SELECTED_ITEMS", null, root)
				return commit('TOGGLE_PREVIEW_PATH_VISIBILITY', !value)
			}

			commit("TOGGLE_PREVIEW_PATH_VISIBILITY", value)

		},
		resetState({ commit }) {
			commit("RESET_STATE")
		},
	},
	getters: {
		selectedItems: state => state.selectedItems,
		suggestions: state => state.suggestions,
		selectedSuggestion: state => state.selectedSuggestion,
		selectedPath: state => state.selectedPath,
		isPreviewVisible: state => state.isPreviewVisible,
		fetchingSuggestions: state => state.fetchingSuggestions
	}
}