<template>
	<section class="share-layer">
		<div class="backdrop" @click="close"></div>
		<div class="share-layer-wrapper fade-in-up">
			<div class="share-layer-head">
				<div class="head-item layer-title is-ellipsis is-scrollable-x">Add new technique</div>
				<div class="head-item close" @click="close">
					<span class="icon">
						<icon icon="times" />
					</span>
				</div>
			</div>
			<div class="share-layer-body">
				<!-- tactic -->
				<div class="body-item email-box">
					<p class="input-wrapper caption has-text-weight-bold has-text-grey is-uppercase is-size-7">Tactic</p>
					<div class="input-wrapper">
						<!-- A drodown that lists only tactic names from navigatorData -->
						<div class="input-container">
							<div class="select">
								<select @change="setTacticInfo($event)">
									<option value="" selected disabled>Select a tactic</option>
									<option
										v-for="tactic in navigatorData[refID]"
										:key="tactic._id"
										:value="tactic._id"
										v-text="tactic.title"
										:selected="tactic._id === tacticID"
									></option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<!-- technique -->
				<div class="body-item email-box fade-in-up" v-if="selectedTactic || tacticID">
					<p class="input-wrapper caption has-text-weight-bold has-text-grey is-uppercase is-size-7">Technique</p>
					<div class="input-wrapper">
						<div class="control is-fullwidth">
							<input
								type="text"
								placeholder="Enter technique title"
								class="input has-text-weight-normal"
								v-model="techniqueConfig.techniques[0].title"
							/>
						</div>
					</div>
				</div>
				<!-- sub-technique -->
				<div class="body-item email-box fade-in-up" v-if="selectedTactic || tacticID">
					<p class="input-wrapper caption has-text-weight-bold has-text-grey is-uppercase is-size-7">
						Sub-Technique
						<span class="icon" @click="addSubtechnique()">
							<icon icon="plus" />
						</span>
					</p>
					<div class="input-wrapper">
						<div class="subtechnique-wrapper" v-if="techniqueConfig.techniques[0].subtechniques?.length > 0">
							<div
								class="subtechnique-item fade-in-down"
								v-for="(subtechnique, index) in techniqueConfig.techniques[0].subtechniques"
								:key="subtechnique"
							>
								<div class="control is-fullwidth">
									<input
										type="text"
										placeholder="Enter sub-technique title"
										class="input is-small"
										v-model="subtechnique.title"
									/>
								</div>
								<span class="control icon" @click="removeSubtechnique(index)">
									<icon icon="times" />
								</span>
							</div>
						</div>
						<div class="subtechnique-wrapper is-empty" v-else>
							No subtechniques found. <span class="is-link" @click="addSubtechnique()">Click here</span> to add one.
						</div>
					</div>
				</div>
			</div>
			<div class="share-layer-foot">
				<div class="foot-item"></div>
				<div class="foot-item">
					<button class="button is-rounded close" @click="addTechnique()" :disabled="!isFormValid()">
						Add Technique
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import axios from "axios"
export default {
	emits: ["close"],
	data() {
		return {
			users: [],
			copiedToClipboard: false,
			fetchingShareList: false,
			techniqueConfig: {
				techniques: [
					{
						parentID: null,
						title: "",
						id: "",
						subtechniques: []
					}
				]
			},
			selectedTactic: this.tacticID
		}
	},
	methods: {
		...mapActions(["appendTechnique"]),
		close() {
			this.$emit("close")
		},
		focus() {
			// this.$nextTick(() => {
			// 	this.$el.querySelector("input").focus()
			// })
		},
		copyLayerLink() {
			let link = ""
			// copy to clipboard for both http and https based links
			if (window.location.protocol === "https:") {
				link = `https://${window.location.host}/share/${this.refID}`
			} else {
				link = `http://${window.location.host}/share/${this.refID}`
			}

			this.copyToClipboard(link)

			//
		},
		copyToClipboard(text) {
			if (this.copiedToClipboard) return

			if (navigator.clipboard) {
				navigator.clipboard
					.writeText(text)
					.then(() => {
						// console.log("Copied to clipboard:", text)
					})
					.catch(err => {
						console.error("Failed to copy to clipboard:", err)
					})
			} else {
				const input = document.createElement("input")
				input.value = text
				input.style.position = "absolute"
				input.style.left = "-9999px"
				input.style.top = "-9999px"
				document.body.appendChild(input)
				input.select()
				document.execCommand("copy")
				document.body.removeChild(input)
			}

			this.copiedToClipboard = true

			// reset after 3 seconnds
			setTimeout(() => {
				this.copiedToClipboard = false
			}, 3000)
		},
		fetchShareList() {
			this.fetchingShareList = true

			let url = `user/layers/${this.refID}/shareList`
			axios
				.get(url)
				.then(response => {
					console.log(response.data)
					if (response.data.success) this.users = response.data.sharedList
					this.fetchingShareList = false
				})
				.catch(() => {
					this.fetchingShareList = false
				})
		},
		addSubtechnique() {
			this.techniqueConfig.techniques[0].subtechniques.push({
				title: ""
			})
		},
		removeSubtechnique(index) {
			this.techniqueConfig.techniques[0].subtechniques.splice(index, 1)
		},
		async addTechnique() {
			if (!this.isFormValid()) return

			let url = "user/technique/upsert"
			let { techniques } = this.techniqueConfig
			let payload = techniques
			let tactic_index = this.tacticIndex

			// add 'parentID'
			payload[0].parentID = this.tacticID

			// delete 'id'
			delete payload[0].id

			axios
				.post(url, payload)
				.then(response => {
					if (!response.data.success) {
						this.close()
						return
					}

					let technique = response.data.techniqueDetails
					console.log("tactic_index:: ", tactic_index)
					if (tactic_index >= 0) this.appendTechnique({ technique, tactic_index })
					this.close()
				})
				.catch(err => {
					console.log(err)
				})
		},
		isFormValid() {
			// let { techniques } = this.techniqueConfig
			// let { tacticID } = this

			// if (this.tacticID) {
			// 	this.techniqueConfig.techniques[0].parentID = this.tacticID
			// }

			// if (!techniques[0].title || !tacticID) return false
			// if (!techniques[0].parentID) return false
			// if (techniques[0].title.length < 3) return false

			return true
		},
		setTacticInfo(event) {
			this.selectedTactic = this.tactidID ? this.tacticID : event?.target?.value
			this.techniqueConfig.techniques[0].parentID = this.selectedTactic
		}
	},
	props: {
		refID: {
			type: String,
			required: true
		},
		tacticID: {
			type: String,
			required: false
		},
		tacticIndex: {
			type: Number,
			required: false
		}
	},
	computed: {
		...mapGetters(["activeLayerTitle", "navigatorData"])
	},
	mounted() {
		console.log("TID:: ", this.tacticID)
		this.setTacticInfo()
	}
}
</script>

<style lang="scss" scoped>
.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	width: 100%;
	height: 100%;

	background-color: hsl(0, 0%, 0%, 0.75);
	transition: background-color 0.2s ease-in-out;

	z-index: 5;
}

.share-layer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 5;
	.share-layer-wrapper {
		z-index: inherit;
		height: 100%;
		width: 100%;
		max-height: 500px;
		max-width: 500px;
		background-color: white;

		border-radius: $gap;
		gap: calc($gap * 2);
		// padding: calc($gap * 3) 0;
		display: flex;
		flex-direction: column;
		gap: 0;
		width: 100%;
		height: 100%;

		overflow: hidden;

		.share-layer-head {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: $gap;

			height: calc($gap * 2 * 3);
			padding: calc($gap * 3);
			z-index: 1;

			width: 100%;
			height: 80px;

			.head-item {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: $gap;

				&.layer-title {
					font-size: 24px;
					font-weight: 500;
					color: hsl(0, 0%, 30%);
				}

				&.close {
					height: 48px;
					width: 100%;
					max-width: 48px;

					border-radius: 10000px;

					display: flex;
					justify-content: center;
					align-items: center;

					&:hover {
						background-color: hsl(0, 0%, 95%);
					}

					.icon {
						cursor: pointer;
						color: hsl(0, 0%, 50%);
					}
				}
			}
		}
		.share-layer-body {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			height: 100%;

			.body-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: $gap;

				.input-wrapper {
					position: relative;

					width: 100%;
					display: flex;
					align-items: center;
					background-color: transparent;
					border-color: hsl(0, 0%, 90%);

					padding: 0 calc($gap * 3);
					gap: $gap;

					.input-container {
						display: flex;
						gap: 0;
						background-color: whitesmoke;
						border-radius: calc($gap/2);
						width: 100%;

						.select {
							height: 40px !important;
							width: 100%;
							select {
								width: 100% !important;
								height: 100% !important;
								background-color: whitesmoke;
								border: none;
							}
						}
					}

					.control {
						height: 100%;
						&.is-fullwidth {
							width: 100%;
						}
						input.input {
							background-color: hsl(0, 0%, 96%);
							border: none;
							box-shadow: none;
							flex: 1;
							height: 48px;
							font-weight: 600;
							color: hsl(0, 0%, 25%);

							&:disabled {
								color: hsl(0, 0%, 65%);
								// background-color: hsl(0, 0%, 92%);
							}
						}
						button.button {
							height: 48px;
						}
					}

					.icon {
						padding: calc($gap/2);
						cursor: pointer;
						border-radius: 1000px;
						color: hsl(0, 0%, 50%);

						&:hover {
							background-color: hsl(0, 0%, 96%);
						}
					}
				}

				&.email-box {
					padding: 0;
					padding-bottom: $gap;

					display: flex;
					flex-direction: column;
				}
				&.is-unscrollable {
					overflow: hidden;
					height: 100%;
				}

				.users-list {
					display: flex;
					flex-direction: column;
					gap: $gap;
					width: 100%;
					height: 100%;
					position: relative;
					overflow-y: auto;

					&.is-empty {
						padding: 0 calc($gap * 4);
						display: flex;
						flex-direction: column;
						gap: 0;
						align-items: center;
						justify-content: center;
						text-align: center;
						color: hsl(0, 0%, 50%);
					}

					.user-item {
						display: flex;
						justify-content: space-between;
						gap: $gap;
						padding: $gap calc($gap * 3);
						border-radius: calc($gap/2);

						&:hover {
							background-color: hsl(0, 0%, 96%);
						}
						.user-details {
							flex: 1;
							height: 100%;

							display: flex;
							gap: $gap;
							align-items: center;

							.user-profile-icon {
								height: 40px;
								width: 40px;

								display: flex;
								align-items: center;
								justify-content: center;
								flex-grow: 0;

								border-radius: 1000px;
								background-color: hsl(0, 0%, 90%);
								overflow: hidden;
							}
							.user-info {
								display: flex;
								flex-direction: column;
								gap: calc($gap / 2);
								flex-grow: 1;

								.name {
									font-weight: 600;
									color: hsl(0, 0%, 30%);
								}
								.email {
									font-weight: 500;
									color: hsl(0, 0%, 40%);
								}
							}
						}
						.user-role {
							color: #666;
							align-items: center;
							display: flex;

							padding: 0 $gap;
						}
					}
				}
			}
		}
		.share-layer-foot {
			padding: calc($gap * 2) calc($gap * 3);
			border-top: 2px solid hsl(0, 0%, 90%);
			display: flex;
			justify-content: space-between;
			z-index: 1;
			height: fit-content;
			.foot-item {
				display: flex;
				align-items: center;

				button.button {
					transition: background-color 0.2s ease-in-out;
				}
				button.button.close {
					background-color: hsl(120, 60%, 35%);
					color: white;
					font-weight: 500;
					border: 0;
					box-shadow: none;
					&:hover {
						background-color: hsl(120, 60%, 30%);
					}
				}

				button.button.copy-link {
					background-color: white;
					border: 1px solid hsl(120, 60%, 35%);
					font-weight: 500;
					color: hsl(120, 60%, 35%);

					&:hover {
						background-color: hsl(120, 30%, 87%);
					}

					&:focus {
						color: hsl(120, 60%, 35%) !important;
					}
				}
			}
		}
	}
}

.is-scrollable-x {
	overflow-x: auto !important;
	// set scrollbar height to 4px
	&::-webkit-scrollbar {
		width: 4px;
	}
}

.select select:focus {
	border: none !important;
	box-shadow: none !important;
}

.subtechnique-wrapper {
	overflow-y: auto;

	display: flex;
	flex-direction: column;
	gap: $gap;
	width: 100%;

	&.is-empty {
		display: flex;
		flex-direction: row;
		gap: calc($gap/2);
		color: hsl(0, 0%, 50%);
		.is-link {
			color: hsl(120, 60%, 35%);
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.subtechnique-item {
		border-radius: $gap;
		display: flex;
		align-items: center;
		gap: $gap;
		color: hsl(0, 0%, 50%);
		.control {
			background-color: hsl(0, 0%, 96%);

			&.icon {
				height: 48px !important;
				width: 48px !important;
				border-radius: 0 !important;
				transition: background-color 0.2s ease-in-out;
				color: hsl(0, 0%, 50%);

				&:hover {
					background-color: hsl(0, 0%, 93%) !important;
				}
			}
		}
	}
}
</style>
