<template>
	<aside class="tactic-details-wrapper">
		<!-- show info message to user -->
		<div class="tactic-details dimmed" v-if="!Object.keys(selectedSuggestion).length">
			Select a Crime Path to see suggestions...
		</div>
		<div class="tactic-details" v-else>
			<div class="tactic-details-header">{{ selectedPath.title ? selectedPath.title : "Select a path" }}</div>
			<div class="tactic-details-body" v-if="Object.keys(selectedPath).length">
				<!-- <div class="tactic-title">{{ selectedPath.title }}</div> -->
				<router-link class="button" :to="{ name: 'NavigatorBase', params: { pathID: selectedPath._id } }"
					>Create Layer</router-link
				>
			</div>
			<div class="tactic-details-foot"></div>
		</div>
	</aside>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
export default {
	data() {
		return {
			refID: ""
		}
	},
	computed: {
		...mapGetters("welcomeSheet", ["selectedItems", "selectedSuggestion", "selectedPath"]),
		...mapGetters(["tabConfig", "navigatorData"])
	},
	methods: {
		...mapActions(["setActiveTab"]),
		...mapActions(["addNewTab", "setNavigatorData", "setActiveTab", "addNewTab"])
	}
}
</script>

<style lang="scss" scoped>
aside.tactic-details-wrapper {
	background-color: hsl(0, 0%, 97%);
	height: 100%;
	max-width: 350px;
	padding: calc($gap * 2);

	border-radius: $gap;
	overflow-y: auto;
	height: 100%;
	width: 100%;
	display: flex;

	// shadow on left
	// box-shadow: -10px 0 10px -10px hsla(0, 0%, 0%, 0.1);
	z-index: 2;
	background-color: hsl(0, 0%, 97%);

	.tactic-details {
		display: flex;
		flex-direction: column;
		gap: $gap;
		width: 100%;

		.tactic-details-header {
			font-size: 1rem;
			font-weight: 600;
			color: $base-text-color;
		}

		.tactic-details-body {
			display: flex;
			flex-direction: column;
			gap: calc($gap/2);
			flex: 1;
			.tactic-title {
				color: $base-hover-text-color;
				font-weight: 500;
			}
		}

		.tactic-details-foot {
			justify-content: flex-end;
			.button {
				&.is-primary {
					background-color: $base-background-color-light;
					color: $base-text-color;
					&:hover {
						background-color: $base-background-color-dark;
					}
				}
			}
		}
	}
}

.dimmed {
	color: $base-text-color-light;
}
</style>
