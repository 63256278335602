<template>
	<div class="import-layer">
		<p class="import-layer-title" v-if="!isLayersListEmpty">
			<span class="caption">Load a saved layer</span><span v-if="isLoadingLayersList" class="button is-loading"></span>
		</p>
		<p class="import-layer-title" v-if="isLayersListEmpty">
			<span class="caption"> No layers found. </span>
		</p>
		<div class="saved-layers">
			<div
				class="saved-layer is-ellipsis"
				v-for="layer in layersList"
				:key="layer"
				@click="getLayerDetails(layer.layerID)"
			>
				<p class="has-text-weight-semibold">
					{{ layer.title }}
				</p>
				<div class="date-text">
					<span class="date">{{ epochToDate(layer.updatedAt).date }}</span>
					<span class="time">{{ epochToDate(layer.updatedAt).time }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios"
import datetimeMixin from "@/mixins/datetime.js"
import { mapActions, mapGetters } from "vuex"
export default {
	mixins: [datetimeMixin],
	data() {
		return {
			isLayersListEmpty: false,
			isLoadingLayersList: true,
			layersList: []
		}
	},
	methods: {
		...mapActions(["setCustomNavigatorData", "selectSingleTechnique", "selectMultipleSubtechnique"]),
		setLayersListLoader(value) {
			this.isLoadingLayersList = value
		},
		fetchAllLayers() {
			let url = "user/layers/shared/fetch"
			// /all?page=1&limit=100"
			axios
				.get(url)
				.then(response => {
					this.setLayersListLoader(false)
					let { layersList } = response.data
					if (layersList?.length) {
						this.layersList = layersList
						return
					}

					this.isLayersListEmpty = true
				})
				.catch(() => {
					this.setLayersListLoader(false)
					// this.isLayersListEmpty = true
				})
		},
		getLayerDetails(layerID = null) {
			if (!layerID) return
			let url = `/user/layers/${layerID}`

			axios.get(url).then(response => {
				let { success } = response.data
				if (!success) return

				let { layer } = response.data
				let payload = {
					tabID: layer.layerID,
					data: layer.selectedItems,
					active: true,
					title: layer.title,
					oldTabID: this.refID
				}
				this.processCustomNavigatorData(payload)
			})
		},
		processCustomNavigatorData(payload) {
			this.setCustomNavigatorData(payload).then(() => this.selectPath(payload))
		},
		selectPath(pathItem) {
			let { procesSubtechniques } = this
			let pathArr = pathItem.data
			let { tabID } = pathItem

			if (!pathArr?.length) return

			pathArr.forEach(path => {
				let { tid, tnid, stnid, color } = path
				let payload = { refID: tabID, tid, tnid, stnid, color }

				this.selectSingleTechnique({ ...payload })
					.then(() => {
						if (stnid != undefined && stnid >= 0) procesSubtechniques({ ...payload })
					})
					.catch(() => {
						console.log("selectSingleTechnique:: FAILURE")
					})
			})
		},
		procesSubtechniques({ refID, tid, tnid, stnid }) {
			let { selectMultipleSubtechnique } = this
			selectMultipleSubtechnique({ refID, tid, tnid, stnid })
		},
		resolveSubtechniqueID({ subtechniqueID }) {
			let stnid = parseInt(subtechniqueID) - 1
			return stnid
		}
	},
	props: {
		refID: {
			type: [String]
		}
	},
	computed: {
		...mapGetters(["activeTab", "tabConfig", "navigatorData"])
	},
	mounted() {
		this.fetchAllLayers()
	}
}
</script>

<style lang="scss" scoped>
.import-layer {
	display: flex;
	flex-direction: column;
	gap: calc($gap/2);
	.import-layer-title {
		display: flex;
		gap: $gap;
		align-items: center;
		span.caption {
			color: $base-text-color-light;
			font-weight: 600;
		}
		// margin-bottom: calc($gap * 2);

		.button {
			background-color: transparent;
			border: 0px;
			height: inherit;
			&::after {
				border: 2px solid hsl(0, 0%, 70%);
				border-right-color: transparent;
				border-top-color: transparent;
			}
		}
	}
	.saved-layers {
		display: flex;
		gap: $gap;
		overflow-x: auto;

		.saved-layer {
			height: 100px;
			width: 100%;
			min-width: 150px;
			max-width: 200px;
			flex-grow: 1;
			background-color: $base-background-color;
			padding: $gap;
			border-radius: calc($gap/2);
			border: 2px solid $base-active-background-color;
			cursor: pointer;

			display: flex;
			flex-direction: column;
			justify-content: space-between;

			&:hover {
				border-color: $base-hover-background-color;
			}

			.date-text {
				display: flex;
				flex-direction: column;

				max-width: 100%;
				word-wrap: break-word;

				span.date,
				span.time {
					font-size: 12px;
					color: $base-text-color-lighter;
					font-weight: 700;
				}
			}
		}
	}
}
</style>
