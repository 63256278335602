<template>
	<div class="signin">
		<div class="signin-form">
			<div class="form-item control">
				<label class="label">Email</label>
				<input type="text" class="input email" placeholder="you@example.com" v-model="signinData.email" />
			</div>
			<div class="form-item control">
				<label class="label">Password</label>
				<input
					type="password"
					class="input password"
					placeholder="******"
					v-model="signinData.password"
					@keyup.enter.prevent="signinWithEmailAndPassword()"
				/>
			</div>
			<div class="form-item control">
				<label class="checkbox">
					<input type="checkbox" v-model="signinData.remember" />
					Remember me
				</label>
			</div>
			<div class="form-item">
				<button class="button" :class="{ 'is-loading': isLoading }" @click="signinWithEmailAndPassword">Sign in</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios"
import { mapActions } from "vuex"

export default {
	data() {
		return {
			signinData: {
				email: null,
				password: null,
				remember: true
			},
			isLoading: false
		}
	},
	methods: {
		...mapActions(["setSigninState", "setAccessToken"]),
		signinWithEmailAndPassword() {
			let { signinData, processSigninResponse } = this
			let baseURL = "/sign-in"

			this.isLoading = true

			axios
				.post(baseURL, signinData)
				.then(response => {
					this.isLoading = false
					processSigninResponse(response.data)
				})
				.catch(() => {
					this.isLoading = false
				})
		},
		processSigninResponse(response) {
			let len = Object.keys(response).length
			let { accessToken } = response

			if (accessToken) this.setAccessToken(accessToken)

			this.setSigninState(true)

			if (len >= 0) this.$router.push({ name: "WelcomeSheetBase" })
		}
	}
}
</script>

<style lang="scss" scoped>
.signin {
	padding: calc($gap * 2);
	border: 3px solid $base-background-color-light;
	border-radius: $gap;
	width: 100%;

	background-color: $base-background-color-light;

	.signin-form {
		display: flex;
		flex-direction: column;
		gap: $gap;

		.form-item {
			width: 100%;

			&.control {
				text-align: left;

				label {
					font-size: 14px;
					color: hsl(0, 0%, 40%);

					&.checkbox {
						display: flex;
						align-items: center;
						gap: calc($gap/2);
					}
				}

				input.input {
					border: none;
				}
			}

			button.button {
				width: 100%;
				transition: background-color 150ms ease-in-out;
				border: none;

				&:focus {
					border: none;
				}
			}
		}
	}
}
</style>
