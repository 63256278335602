const BASE = {
	hue: "0",
	saturation: "0%",
	lightness: "80%",
	color: "white"
}

const SET_1 = [
	{
		hue: "0",
		saturation: "30%",
		lightness: "50%",
		color: "white",
		background_color: "hsl(0,30%,50%)"
	},
	{
		hue: "0",
		saturation: "40%",
		lightness: "50%",
		background_color: "hsl(0,40%,50%)",
		color: "white"
	},
	{
		hue: "0",
		saturation: "50%",
		lightness: "50%",
		background_color: "hsl(0,50%,50%)",
		color: "white"
	},
	{
		hue: "0",
		saturation: "60%",
		lightness: "50%",
		background_color: "hsl(0,60%,50%)",
		color: "white"
	},
	{
		hue: "0",
		saturation: "70%",
		lightness: "50%",
		background_color: "hsl(0,70%,50%)",
		color: "white"
	}
]

const SET_2 = [
	{
		hue: "30",
		saturation: "30%",
		lightness: "50%",
		background_color: "hsl(30,30%,50%)",
		color: "white"
	},
	{
		hue: "30",
		saturation: "40%",
		lightness: "50%",
		background_color: "hsl(30,40%,50%)",
		color: "white"
	},
	{
		hue: "30",
		saturation: "50%",
		lightness: "50%",
		background_color: "hsl(30,50%,50%)",
		color: "white"
	},
	{
		hue: "30",
		saturation: "60%",
		lightness: "50%",
		background_color: "hsl(30,60%,50%)",
		color: "white"
	},
	{
		hue: "30",
		saturation: "70%",
		lightness: "50%",
		background_color: "hsl(30,70%,50%)",
		color: "white"
	}
]

const SET_3 = [
	{
		hue: "60",
		saturation: "30%",
		lightness: "50%",
		background_color: "hsl(60,30%,50%)",
		color: "white"
	},
	{
		hue: "60",
		saturation: "40%",
		lightness: "50%",
		background_color: "hsl(60,40%,50%)",
		color: "white"
	},
	{
		hue: "60",
		saturation: "50%",
		lightness: "50%",
		background_color: "hsl(60,50%,50%)",
		color: "white"
	},
	{
		hue: "60",
		saturation: "60%",
		lightness: "50%",
		background_color: "hsl(60,60%,50%)",
		color: "white"
	},
	{
		hue: "60",
		saturation: "70%",
		lightness: "50%",
		background_color: "hsl(60,70%,50%)",
		color: "white"
	}
]

const SET_4 = [
	{
		hue: "90",
		saturation: "30%",
		lightness: "50%",
		background_color: "hsl(90,30%,50%)",
		color: "white"
	},
	{
		hue: "90",
		saturation: "40%",
		lightness: "50%",
		background_color: "hsl(90,40%,50%)",
		color: "white"
	},
	{
		hue: "90",
		saturation: "50%",
		lightness: "50%",
		background_color: "hsl(90,50%,50%)",
		color: "white"
	},
	{
		hue: "90",
		saturation: "60%",
		lightness: "50%",
		background_color: "hsl(90,60%,50%)",
		color: "white"
	},
	{
		hue: "90",
		saturation: "70%",
		lightness: "50%",
		background_color: "hsl(90,70%,50%)",
		color: "white"
	}
]

const SET_5 = [
	{
		hue: "150",
		saturation: "30%",
		lightness: "50%",
		background_color: "hsl(150,30%,50%)",
		color: "white"
	},
	{
		hue: "150",
		saturation: "40%",
		lightness: "50%",
		background_color: "hsl(150,40%,50%)",
		color: "white"
	},
	{
		hue: "150",
		saturation: "50%",
		lightness: "50%",
		background_color: "hsl(150,50%,50%)",
		color: "white"
	},
	{
		hue: "150",
		saturation: "60%",
		lightness: "50%",
		background_color: "hsl(150,60%,50%)",
		color: "white"
	},
	{
		hue: "150",
		saturation: "70%",
		lightness: "50%",
		background_color: "hsl(150,70%,50%)",
		color: "white"
	}
]

const SET_6 = [
	{
		hue: "180",
		saturation: "30%",
		lightness: "50%",
		background_color: "hsl(180,30%,50%)",
		color: "white"
	},
	{
		hue: "180",
		saturation: "40%",
		lightness: "50%",
		background_color: "hsl(180,40%,50%)",
		color: "white"
	},
	{
		hue: "180",
		saturation: "50%",
		lightness: "50%",
		background_color: "hsl(180,50%,50%)",
		color: "white"
	},
	{
		hue: "180",
		saturation: "60%",
		lightness: "50%",
		background_color: "hsl(180,60%,50%)",
		color: "white"
	},
	{
		hue: "180",
		saturation: "70%",
		lightness: "50%",
		background_color: "hsl(180,70%,50%)",
		color: "white"
	}
]

const SET_7 = [
	{
		hue: "240",
		saturation: "30%",
		lightness: "50%",
		background_color: "hsl(240,30%,50%)",
		color: "white"
	},
	{
		hue: "240",
		saturation: "40%",
		lightness: "50%",
		background_color: "hsl(240,40%,50%)",
		color: "white"
	},
	{
		hue: "240",
		saturation: "50%",
		lightness: "50%",
		background_color: "hsl(240,50%,50%)",
		color: "white"
	},
	{
		hue: "240",
		saturation: "60%",
		lightness: "50%",
		background_color: "hsl(240,60%,50%)",
		color: "white"
	},
	{
		hue: "240",
		saturation: "70%",
		lightness: "50%",
		background_color: "hsl(240,70%,50%)",
		color: "white"
	}
]
const SET_8 = [
	{ hue: "300", saturation: "30%", lightness: "50%", background_color: "hsl(300,30%,50%)", color: "white" },
	{ hue: "300", saturation: "40%", lightness: "50%", background_color: "hsl(300,40%,50%)", color: "white" },
	{ hue: "300", saturation: "50%", lightness: "50%", background_color: "hsl(300,50%,50%)", color: "white" },
	{ hue: "300", saturation: "60%", lightness: "50%", background_color: "hsl(300,60%,50%)", color: "white" },
	{ hue: "300", saturation: "70%", lightness: "50%", background_color: "hsl(300,70%,50%)", color: "white" }
]

export default { BASE, SET_1, SET_2, SET_3, SET_4, SET_5, SET_6, SET_7, SET_8 }
