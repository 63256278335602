import MarkdownIt from "markdown-it"

const md = new MarkdownIt()
var defaultRender =
	md.renderer.rules.link_open ||
	function (tokens, idx, options, _, self) {
		return self.renderToken(tokens, idx, options)
	}

md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
	// If you are sure other plugins can't add `target` - drop check below
	var aIndex = tokens[idx].attrIndex("target")

	if (aIndex < 0) tokens[idx].attrPush(["target", "_blank"])
	else tokens[idx].attrs[aIndex][1] = "_blank" // replace value of existing attr

	// pass token to default renderer.
	return defaultRender(tokens, idx, options, env, self)
}

export default {
	parse(item) {
		return item ? md.render(item) : null
	}
}
