import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store/store"
import markdown from "@/mixins/markdown.js"
import axios from "axios"

// custom js
import "@/assets/js/icons.js"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

const app = createApp(App)

axios.defaults.headers.common["x-access-token"] = localStorage.getItem("accessToken")
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

app.use(store).use(router).component("icon", FontAwesomeIcon)
app.config.globalProperties.$md = markdown
app.mount("#app")
