<template>
	<BaseHeader>
		<button class="button is-light" v-if="isLoggedIn" @click="signout">
			<span class="icon">
				<icon icon="sign-out-alt" />
			</span>
			<span>Signout</span>
		</button>
	</BaseHeader>
	<section class="welcome-sheet">
		<!-- Sidebar  -->
		<div class="welcome-sheet-item sidebar-wrapper">
			<WelcomeSheetSidebar />
		</div>
		<!-- Body content  -->
		<div class="welcome-sheet-item base-body">
			<WelcomeSheetBody />
		</div>
	</section>
</template>

<script>
import BaseHeader from "@/components/BaseHeader.vue"
import WelcomeSheetBody from "@/components/WelcomeSheet/WelcomeSheetBody.vue"
import WelcomeSheetSidebar from "@/components/WelcomeSheet/WelcomeSheetSidebar.vue"
import { mapActions, mapGetters } from "vuex"

export default {
	components: {
		BaseHeader,
		WelcomeSheetBody,
		WelcomeSheetSidebar
	},
	methods: {
		...mapActions("welcomeSheet", ["resetState"]),
		...mapActions(["signout"])
	},
	computed: {
		...mapGetters(["isLoggedIn"])
	},
	unmounted() {
		this.resetState()
	}
}
</script>

<style lang="scss" scoped>
.welcome-sheet {
	position: relative;
	display: flex;
	width: 100%;
	height: calc(100vh - 48px);

	.welcome-sheet-item {
		position: relative;
		width: 100%;
		background-color: inherit;

		&.sidebar-wrapper {
			width: fit-content;
		}

		&.base-body {
			display: flex;
			gap: $gap;
			flex: 1;
			padding: calc($gap * 1.5);
		}
	}
}
</style>
