// Authentication store
import axios from "axios"

const root = { root: true }
export default {
	state: {
		accessToken: localStorage.getItem("accessToken") || null,
		refreshToken: localStorage.getItem("refreshToken") || null
	},
	mutations: {},
	actions: {
		userSignup: ({ commit }, payload) => {
			let url = "http://crime.navigator:8081/sign-in"

			axios.post(url, payload).then(response => {
				let { accessToken, refreshToken, success } = response.data

				if (success) return
				// Commit to store
				commit("SET_ACCESS_TOKEN", accessToken, root)
				commit("SET_REFRESH_TOKEN", refreshToken, root)
			})
		}
	}
}
