import COLORS from "./colors.js"
export default {
	PALETTE: {
		// Dark theme (default) Color palette here
		BASE: COLORS.BASE,
		DARK: [
			...COLORS.SET_1,
			...COLORS.SET_2,
			...COLORS.SET_3,
			...COLORS.SET_4,
			...COLORS.SET_5,
			...COLORS.SET_6,
			...COLORS.SET_7,
			...COLORS.SET_8
		],
		// Light theme Color palette here
		LIGHT: {}
	},
	baseTabConfig: {
		title: "Layer",
		active: false
	},
	baseTechniqueConfig: [
		{
			key: "procedure",
			title: "Procedure"
		},
		{
			key: "mitigation",
			title: "Mitigation"
		}
		// {
		// 	key: "investigation",
		// 	title: "Investigation Steps"
		// },
		// {
		// 	key: "tools",
		// 	title: "Tools of Discovery"
		// }
	],
	SECTION_MODE: {
		EDIT: 0,
		PREVIEW: 1
	},
	TABLE_STATES: {
		// Current State
		BASE_STATE: 0,
		// Loading data
		LOADING: 0,
		// Displaying defaults state
		ACTIVE: 1,
		// Reloading data
		RELOADING: 2,
		// Empty table response
		EMPTY: 3,
		// Error responses
		SERVER_ERROR: 4
	}
}
