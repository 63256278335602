<template>
	<router-view />
</template>

<script>
import { mapActions } from "vuex"

export default {
	methods: {
		...mapActions(["fetchNavigatorData"])
	},
	mounted() {
		this.fetchNavigatorData()
	}
}
</script>

<style lang="scss">
#app {
	font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
		Helvetica Neue, Helvetica, Arial, sans-serif;
	background-color: $base-background-color;
	color: $base-active-text-color-light;

	font-size: 14px;
	height: 100%;
	padding: 0;
	margin: 0;
	overflow: scroll;
}
</style>
