<template>
	<section v-if="isValidHandle()">
		<div class="handle-body">
			<table class="table is-hoverable is-fullwidth">
				<tbody>
					<tr v-if="handle.isp">
						<td><strong>Service Provider</strong></td>
						<td>{{ handle?.isp }}</td>
					</tr>
					<template v-if="isExpandedTableRows">
						<tr v-if="handle.org">
							<td><strong>Organisation</strong></td>
							<td>{{ handle?.org }}</td>
						</tr>
						<!-- Country -->
						<tr v-if="handle.country">
							<td><strong>Country</strong></td>
							<td>
								<span> {{ handle?.country }}</span>

								<span v-if="handle?.countryCode" class="has-text-weight-semibold has-text-grey">
									({{ handle?.countryCode }})
								</span>
							</td>
						</tr>

						<!-- state -->
						<tr v-if="handle.regionName">
							<td><strong>State</strong></td>
							<td>
								<span>{{ handle?.regionName }}</span>
								<span v-if="handle?.region" class="has-text-weight-semibold has-text-grey">
									({{ handle?.region }})
								</span>
							</td>
						</tr>
					</template>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="2">
							<HandleHeader :isExpandedTableRows="isExpandedTableRows" @toggle="toggleTableDetails()" />
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</section>
	<section class="handle-body" v-else>No IP Found</section>
</template>

<script>
import HandleHeader from "@/components/handles/HandleHeader.vue"
export default {
	components: {
		HandleHeader
	},
	props: {
		handle: {
			type: [Object],
			required: false // to check for null, undefined
		}
	},
	data() {
		return {
			isExpandedTableRows: false
		}
	},
	methods: {
		isValidHandle() {
			return !!this.handle && Object.keys(this.handle)?.length > 0
		},
		toggleTableDetails() {
			this.isExpandedTableRows = !this.isExpandedTableRows
		}
	}
}
</script>

<style lang="scss" scoped>
.handle-body {
	display: flex;
	flex-direction: column;
}
.table {
	position: relative;
	tbody {
		tr {
			td {
				&:first-child {
					width: 30% !important;
				}
			}
		}
	}
	tfoot {
		width: 100%;
		tr td {
			padding: 0;
		}
	}
}
</style>
