<template>
	<aside class="sidebar">
		<!-- Sidebar header  -->
		<div class="sidebar-header">
			<p class="caption is-uppercase has-text-grey has-text-weight-bold is-size-7">SEARCH</p>

			<div class="reset-wrapper">
				<p class="reset-selected-items is-size-7" @click="resetPage">Reset all</p>
			</div>
		</div>
		<!-- Sidebar body  -->
		<div class="sidebar-body">
			<div class="input-wrapper">
				<input
					ref="input-keywords"
					type="text"
					class="input is-rounded"
					placeholder="olx, fraud, fake bank account"
					@keyup.enter="processKeyword"
					v-model="keyword"
					minlength="keywordMinLength"
					maxlength="keywordMaxLength"
					autofocus
				/>
				<button class="button search-icon" @click="processKeyword">
					<span class="icon">
						<icon icon="search" />
					</span>
				</button>
				<div class="search-footer">
					<!-- SUbtle information for user  -->
					<div class="information">
						<p>hit <kbd>enter</kbd> to send</p>
					</div>
					<!-- Counter  -->
					<div
						class="counter"
						:class="[
							{ 'has-text-danger': keyword?.length > keywordMaxLength },
							{ 'has-text-warning': keyword?.length >= keywordLengthMedian && keyword?.length <= keywordMaxLength },
							{ 'has-text-success': isKeywordValid() }
						]"
					>
						<span> {{ keyword?.length }} </span>
						<span>/{{ keywordMaxLength }}</span>
					</div>
				</div>
			</div>

			<SelectedSearchItems @reset-page="resetPage" @remove-item="setinputFocus" />
		</div>

		<!-- Sidebar footer  -->
		<div class="sidebar-foot">
			<div class="buttons">
				<div class="button is-light is-fit-width" @click="fetchSuggestions">
					<icon icon="redo" />
					<span>Reload</span>
				</div>
			</div>
		</div>
	</aside>
</template>

<script>
import SelectedSearchItems from "@/components/SelectedSearchItems.vue"
import { mapActions, mapGetters } from "vuex"

export default {
	data() {
		return {
			keyword: "",
			keywordMinLength: 3,
			keywordMaxLength: 25,
			keywordLengthMedian: 20
		}
	},
	components: {
		SelectedSearchItems
	},
	methods: {
		...mapActions("welcomeSheet", ["addKeyword", "resetAllSelectedItems", "fetchSuggestions"]),
		processKeyword() {
			let { keyword, keywordMinLength, keywordMaxLength } = this
			console.log(keyword)

			if (typeof keyword !== "string") return
			if (!keyword.length) return
			if (keyword.length < keywordMinLength) return
			if (keyword.length > keywordMaxLength) return

			// if (isDuplicate(keyword)) {
			// 	this.keyword = ""
			// 	return
			// }

			this.addKeyword(keyword)
				.then(() => (this.keyword = ""))
				.catch(err => console.log(err))
		},
		isDuplicate(keyword) {
			let { selectedItems } = this
			let isDuplicate = false

			selectedItems.forEach(item => (isDuplicate = item === keyword ? true : false))

			return isDuplicate
		},
		resetPage() {
			this.keyword = ""
			this.resetAllSelectedItems()
			this.setinputFocus()
		},
		setinputFocus() {
			this.$refs["input-keywords"].focus()
		},
		isKeywordValid() {
			// min length > 0
			// max length < 25 characters
			return this.keyword.length >= this.keywordMinLength && this.keyword.length <= this.keywordMaxLength
		}
	},
	computed: {
		...mapGetters("welcomeSheet", ["selectedItems"])
	}
}
</script>

<style lang="scss" scoped>
aside.sidebar {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;

	height: 100%;
	width: 500px;

	background-color: white;
	padding: $gap;
	border-right: 1px solid $base-hover-background-color;

	.sidebar-header {
		display: flex;
		gap: $gap;
		margin-bottom: $gap;
		justify-content: space-between;
		.reset-wrapper {
			display: flex;
			flex-wrap: nowrap;
			gap: $gap;
			.reset-selected-items {
				color: hsl(0, 0%, 60%);
				&:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}
	}

	.sidebar-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: calc($gap * 2);
		height: 100%;
		overflow-y: hidden;
		padding: calc($gap/2);

		.input-wrapper {
			display: flex;
			flex-direction: column;
			gap: calc($gap/2);
			position: relative;

			.button.button {
				&.search-icon {
					width: fit-content;
					border-radius: 10000px;
					position: absolute;
					right: 2px;
					height: 36px;
					width: 36px;
					top: 2px;
					cursor: pointer;

					transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

					&:hover {
						background-color: hsl(0, 0%, 94%);
						border-color: hsl(0, 0%, 86%);
					}

					.icon {
						cursor: pointer;
						color: hsl(0, 0%, 50%);
					}
				}
			}

			.search-footer {
				display: flex;
				gap: $gap;
				justify-content: space-between;
				align-items: center;

				padding: 0 calc($gap * 1.5);

				.counter {
					font-size: 12px;
					color: hsl(0, 0%, 60%);
					align-self: flex-end;
					span {
						font-weight: bold;
					}
				}

				p {
					font-size: 12px;
					color: hsl(0, 0%, 60%);
					align-self: flex-end;

					display: flex;
					justify-content: center;
					align-items: center;
					gap: calc($gap/2);

					opacity: 0.7;
				}
			}
		}
	}

	.sidebar-foot {
		padding: $gap 0;
		.buttons {
			justify-content: flex-end;
			.button {
				gap: calc($gap/2);
				font-size: 14px;
			}
		}
	}

	.item-details {
		display: flex;
		flex-direction: column;
		gap: $gap;

		.item-name {
			font-size: 16px;
			font-weight: bold;
		}
	}
}

.is-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

kbd {
	background-color: hsl(0, 0%, 94%);
	border-radius: calc($gap/2);
	border: 1px solid hsl(0, 0%, 86%);
	color: hsl(0, 0%, 50%);
	display: inline-block;
	font-size: 0.85em;
	font-weight: 700;
	line-height: 1;
	padding: 2px 4px;
	white-space: nowrap;
}

.has-text-warning {
	color: hsl(44, 100%, 59%) !important;
}
</style>
