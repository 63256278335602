import { library } from "@fortawesome/fontawesome-svg-core"
import {
	faPlus,
	faTimes,
	faMagnifyingGlass,
	faPalette,
	faChevronUp,
	faChevronDown,
	faExclamationTriangle,
	faCheckCircle,
	faLink,
	faTintSlash,
	faEdit,
	faDownload,
	faEye,
	faUser,
	faUndo,
	faRedo,
	faTimesCircle,
	faChevronRight,
	faSignOutAlt,
	faSearch,
	faAngleDown,
	faTrash,
	faMinus,
	faAngleUp,
	faAnglesDown,
	faAnglesUp,
	faArrowUpFromBracket,
	faCopy,
	faCheck,
	faLocationPin,
	faFileLines
} from "@fortawesome/free-solid-svg-icons"

library.add(
	faPlus,
	faTimes,
	faMagnifyingGlass,
	faPalette,
	faChevronUp,
	faChevronDown,
	faExclamationTriangle,
	faCheckCircle,
	faLink,
	faTintSlash,
	faEdit,
	faDownload,
	faEye,
	faUser,
	faUndo,
	faRedo,
	faTimesCircle,
	faChevronRight,
	faSignOutAlt,
	faSearch,
	faAngleDown,
	faAngleUp,
	faTrash,
	faMinus,
	faAnglesDown,
	faAnglesUp,
	faArrowUpFromBracket,
	faCopy,
	faCheck,
	faLocationPin,
	faFileLines
)
