// Backend API store
import axios from "axios"
// const root = { root: true }

export default {
	state: {},
	mutations: {},
	actions: {
		createNewLayer: (_, customTitle) => {
			let url = "/user/layers/create"
			let payload = {
				title: customTitle
			}

			return new Promise((resolve, reject) => {
				axios
					.post(url, payload)
					.then(response => {
						let { layerID } = response.data
						resolve(layerID)
					})
					.catch(error => {
						console.log("ERROR:: ", error)
						reject(error)
					})
			})
		}
	}
}
