<template>
	<section class="share-layer">
		<div class="backdrop" @click="close"></div>
		<div class="share-layer-wrapper fade-in-up">
			<div class="share-layer-head">
				<div class="head-item layer-title is-ellipsis is-scrollable-x">Share {{ activeLayerTitle }}</div>
				<div class="head-item close" @click="close">
					<span class="icon">
						<icon icon="times" />
					</span>
				</div>
			</div>
			<div class="share-layer-body">
				<div class="body-item email-box">
					<div class="input-wrapper">
						<div class="control is-fullwidth input-container">
							<input type="text" placeholder="Enter email" class="input" v-model="shareLayerUserConfig.email" />
							<div class="select is-small">
								<select v-model="shareLayerUserConfig.type">
									<option value="VIEWER">Viewer</option>
									<option value="EDITOR">Editor</option>
								</select>
							</div>
						</div>

						<div class="control">
							<button class="button is-light" @click="shareLayer">
								<icon icon="plus" />
							</button>
						</div>
					</div>
				</div>
				<div class="body-item is-unscrollable">
					<div class="users-list" v-if="users?.length">
						<div class="user-item" v-for="(user, i) in users" :key="user">
							<div class="user-details">
								<div class="user-profile-icon">
									<icon icon="user" />
								</div>
								<div class="user-info">
									<div class="name" v-if="user.name">{{ user.name }}</div>
									<div class="email">{{ user.email }}</div>
								</div>
							</div>
							<div class="user-role control">
								<!-- select dropdown that calls updatePermission on select-->
								<div class="select is-small">
									<select @change="updatePermission($event, user)" v-model="users[i].type">
										<option value="VIEWER">Viewer</option>
										<option value="EDITOR">Editor</option>
									</select>
								</div>
							</div>
						</div>
					</div>

					<div class="users-list is-empty" v-else>
						<h1 class="is-size-5 has-text-grey has-text-weight-semibold">No users found</h1>
						<h5 class="is-size-6 has-text-grey has-text-weight-normal">
							<p>Please add an email to share access with someone</p>
						</h5>
					</div>
				</div>
			</div>
			<div class="share-layer-foot">
				<div class="foot-item">
					<button class="button is-bordered is-success copy-link" @click="copyLayerLink">
						<span class="icon">
							<icon icon="check" v-if="copiedToClipboard" />
							<icon icon="copy" v-if="!copiedToClipboard" />
						</span>
						<span>{{ copiedToClipboard ? "Copied!" : "Copy Link" }}</span>
					</button>
				</div>
				<div class="foot-item">
					<button class="button is-rounded close" @click="close">Done</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters } from "vuex"
import axios from "axios"
export default {
	emits: ["close"],
	data() {
		return {
			users: [],
			copiedToClipboard: false,
			fetchingShareList: false,
			shareLayerUserConfig: {
				email: "",
				type: "VIEWER"
			}
		}
	},
	methods: {
		close() {
			this.$emit("close")
		},
		focus() {
			this.$nextTick(() => {
				this.$el.querySelector("input").focus()
			})
		},
		copyLayerLink() {
			let link = ""
			// copy to clipboard for both http and https based links
			if (window.location.protocol === "https:") {
				link = `https://${window.location.host}/share/${this.refID}`
			} else {
				link = `http://${window.location.host}/share/${this.refID}`
			}

			this.copyToClipboard(link)

			//
		},
		copyToClipboard(text) {
			if (this.copiedToClipboard) return

			if (navigator.clipboard) {
				navigator.clipboard
					.writeText(text)
					.then(() => {
						// console.log("Copied to clipboard:", text)
					})
					.catch(err => {
						console.error("Failed to copy to clipboard:", err)
					})
			} else {
				const input = document.createElement("input")
				input.value = text
				input.style.position = "absolute"
				input.style.left = "-9999px"
				input.style.top = "-9999px"
				document.body.appendChild(input)
				input.select()
				document.execCommand("copy")
				document.body.removeChild(input)
			}

			this.copiedToClipboard = true

			// reset after 3 seconnds
			setTimeout(() => {
				this.copiedToClipboard = false
			}, 3000)
		},
		fetchShareList() {
			this.fetchingShareList = true

			let url = `user/layers/${this.refID}/shareList`
			axios
				.get(url)
				.then(response => {
					console.log(response.data)
					if (response.data.success) this.users = response.data.sharedList
					this.fetchingShareList = false
				})
				.catch(() => {
					this.fetchingShareList = false
				})
		},
		shareLayer() {
			let url = `user/layers/${this.refID}/share`
			let { email, type } = this.shareLayerUserConfig
			let payload = { email, type }

			axios
				.post(url, payload)
				.then(response => {
					console.log(response.data)
					this.fetchShareList()

					// reset input
					this.shareLayerUserConfig.email = ""
					this.shareLayerUserConfig.type = "VIEWER"
				})
				.catch(err => {
					console.log(err)
				})
		},
		updatePermission(event, user) {
			let url = `user/layers/${this.refID}/share/permission`
			let { email } = user
			let payload = { email: email, type: event.target.value }

			axios.post(url, payload).then(response => {
				console.log(response.data)
				this.fetchShareList()
			})
		}
	},
	props: {
		refID: {
			type: String,
			required: true
		}
	},
	computed: {
		...mapGetters(["activeLayerTitle"])
	},
	mounted() {
		this.focus()
		this.fetchShareList()
	}
}
</script>

<style lang="scss" scoped>
.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	width: 100%;
	height: 100%;

	background-color: hsl(0, 0%, 0%, 0.75);
	transition: background-color 0.2s ease-in-out;

	z-index: 5;
}

.share-layer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 5;
	.share-layer-wrapper {
		z-index: inherit;
		height: 100%;
		width: 100%;
		max-height: 500px;
		max-width: 500px;
		background-color: white;

		border-radius: $gap;
		gap: calc($gap * 2);
		// padding: calc($gap * 3) 0;
		display: flex;
		flex-direction: column;
		gap: 0;
		width: 100%;
		height: 100%;

		overflow: hidden;

		.share-layer-head {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: $gap;

			height: calc($gap * 2 * 3);
			padding: calc($gap * 3);
			z-index: 1;

			width: 100%;
			height: 80px;

			.head-item {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: $gap;

				&.layer-title {
					font-size: 24px;
					font-weight: 500;
				}

				&.close {
					height: 48px;
					width: 100%;
					max-width: 48px;

					border-radius: 10000px;

					display: flex;
					justify-content: center;
					align-items: center;

					&:hover {
						background-color: hsl(0, 0%, 95%);
					}

					.icon {
						cursor: pointer;
					}
				}
			}
		}
		.share-layer-body {
			flex: 1;
			display: flex;
			flex-direction: column;
			// gap: calc($gap * 2);
			overflow: hidden;
			height: 100%;

			.body-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: $gap;

				.input-wrapper {
					position: relative;

					display: flex;
					// justify-content: center;
					align-items: center;
					width: 100%;
					background-color: transparent;
					border-color: hsl(0, 0%, 90%);

					padding: 0 calc($gap * 3);
					gap: $gap;

					.input-container {
						display: flex;
						gap: 0;
						background-color: whitesmoke;
						border-radius: calc($gap/2);

						.select {
							height: 48px !important;
							select {
								height: 100% !important;
								background-color: whitesmoke;
								border: none;
							}
						}
					}

					.control {
						height: 100%;
						&.is-fullwidth {
							width: 100%;
						}
						input.input {
							background-color: hsl(0, 0%, 96%);
							border: none;
							box-shadow: none;
							flex: 1;
							height: 48px;
							font-weight: 600;
							color: hsl(0, 0%, 25%);

							&:disabled {
								color: hsl(0, 0%, 65%);
								// background-color: hsl(0, 0%, 92%);
							}
						}
						button.button {
							height: 48px;
						}
					}
				}

				&.email-box {
					padding: 0;
					padding-bottom: $gap;
				}
				&.is-unscrollable {
					overflow: hidden;
					height: 100%;
				}

				.users-list {
					display: flex;
					flex-direction: column;
					gap: $gap;
					width: 100%;
					height: 100%;
					position: relative;
					overflow-y: auto;

					&.is-empty {
						padding: 0 calc($gap * 4);
						display: flex;
						flex-direction: column;
						gap: 0;
						align-items: center;
						justify-content: center;
						text-align: center;
					}

					.user-item {
						display: flex;
						justify-content: space-between;
						gap: $gap;
						padding: $gap calc($gap * 3);
						border-radius: calc($gap/2);

						&:hover {
							background-color: hsl(0, 0%, 96%);
						}
						.user-details {
							flex: 1;
							height: 100%;

							display: flex;
							gap: $gap;
							align-items: center;

							.user-profile-icon {
								height: 40px;
								width: 40px;

								display: flex;
								align-items: center;
								justify-content: center;
								flex-grow: 0;

								border-radius: 1000px;
								background-color: hsl(0, 0%, 90%);
								overflow: hidden;
							}
							.user-info {
								display: flex;
								flex-direction: column;
								gap: calc($gap / 2);
								flex-grow: 1;

								.name {
									font-weight: 600;
									color: hsl(0, 0%, 30%);
								}
								.email {
									font-weight: 500;
									color: hsl(0, 0%, 40%);
								}
							}
						}
						.user-role {
							color: #666;
							align-items: center;
							display: flex;

							padding: 0 $gap;
						}
					}
				}
			}
		}
		.share-layer-foot {
			padding: calc($gap * 2) calc($gap * 3);
			border-top: 2px solid hsl(0, 0%, 90%);
			display: flex;
			justify-content: space-between;
			z-index: 1;
			height: fit-content;
			.foot-item {
				display: flex;
				align-items: center;

				button.button {
					transition: background-color 0.2s ease-in-out;
				}
				button.button.close {
					background-color: hsl(120, 60%, 35%);
					color: white;
					font-weight: 500;
					border: 0;
					box-shadow: none;
					&:hover {
						background-color: hsl(120, 60%, 30%);
					}
				}

				button.button.copy-link {
					background-color: white;
					border: 1px solid hsl(120, 60%, 35%);
					font-weight: 500;
					color: hsl(120, 60%, 35%);

					&:hover {
						background-color: hsl(120, 30%, 87%);
					}

					&:focus {
						color: hsl(120, 60%, 35%) !important;
					}
				}
			}
		}
	}
}

.is-scrollable-x {
	overflow-x: auto !important;
	// set scrollbar height to 4px
	&::-webkit-scrollbar {
		width: 4px;
	}
}

.select select:focus {
	border: none !important;
	box-shadow: none !important;
}
</style>
