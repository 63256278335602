<template>
	<section class="handle-header" @click="toggleHandleVisibility">
		<div class="handle-header-item">
			<span class="icon">
				<icon icon="angles-up" v-if="isExpandedTableRows" />
				<icon icon="angles-down" v-else />
			</span>
			<!-- <span>
				{{ isExpandedTableRows ? "Collapse" : "Expand" }}
			</span> -->
		</div>
	</section>
</template>

<script>
export default {
	props: {
		isExpandedTableRows: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		toggleHandleVisibility() {
			this.$emit("toggle", this.isExpandedTableRows)
		}
	}
}
</script>

<style lang="scss" scoped>
.handle-header {
	display: flex;
	gap: $gap;
	align-items: center;
	justify-content: center;

	width: 100%;
	padding: calc($gap/2) 0;
	border-bottom-left-radius: $gap;
	border-bottom-right-radius: $gap;

	&:hover {
		background-color: hsl(0, 0%, 94%);
		cursor: pointer;
	}

	.handle-header-item {
		display: flex;
		align-items: center;

		cursor: pointer;
		color: hsl(0, 0%, 50%);
		font-size: calc($gap * 1.6);
		font-weight: 600;

		&:hover {
			color: hsl(0, 0%, 40%);
		}
	}
}
</style>
