<template>
	<div class="navigator-wrapper" :style="getBaseStyle(BASE_COLOR)">
		<!-- <div class="search-bar">
			<input type="text" v-model="searchQuery" placeholder="Search techniques or sub-techniques"
				class="search-input" />
		</div> -->
		<div class="tactic-wrapper" v-for="(tactic, tactic_index) in parsedNavigatorData" :key="tactic_index"
			v-show="getTotalSelectedItems(tactic) || !showSelectedItems">
			<div class="tactic is-unselectable">
				<div class="tactic-title is-capitalise">{{ tactic.title }}</div>
				<div class="tactic-counter">{{ tactic.techniques?.length }} techniques</div>
			</div>
			<div class="techniques-wrapper">
				<div id="technique" class="technique-wrapper" :class="[
					{ 'is-empty': !technique.subtechniques?.length },
					{
						'is-hidden':
							showSelectedItems && !technique?.is_selected && !getSelectedSubtechniqueCount(technique.subtechniques)
					},
					{
						'is-selected-active': technique.is_selected_active
					},
					{
						'is-selected': technique.is_selected
					}
				]" v-for="(technique, technique_index) in tactic.techniques" :key="technique_index"
					:style="getTechniqueStyle(technique)">
					<div class="technique is-unselectable" :class="[{ 'is-empty': !technique.subtechniques?.length }]">
						<div class="colors">
							<div v-for="color in technique.colors" :key="color" class="color"
								:style="configureColorsBox(color)"></div>
						</div>
						<div class="technique-item"
							@click.exact="selectSingleTechnique({ refID, tid: tactic_index, tnid: technique_index })"
							@click.shift="selectMultipleTechnique({ refID, tid: tactic_index, tnid: technique_index })"
							@click.meta="selectMultipleTechnique({ refID, tid: tactic_index, tnid: technique_index })">
							<div class="technique-title">{{ technique.title }}</div>
							<div class="technique-counter" v-if="technique.subtechniques">
								<span>{{ getSelectedSubtechniqueCount(technique.subtechniques) }}</span>
								<span>/</span>
								<span>{{ technique.subtechniques.length }}</span>
							</div>
						</div>

						<div class="technique-item is-unhoverable is-justify-content-flex-end">
							<div class="expand-subtechnique"
								v-if="technique.subtechniques && technique.subtechniques.length"
								@click.exact="toggleSubtechnique({ tid: tactic_index, tnid: technique_index, refID })">
								<icon icon="chevron-down" :class="technique.is_expanded ? 'fa-flip-vertical' : ''" />
							</div>
							<div class="link is-narrow" @click="openTechnique(technique, tactic_index)">
								<icon icon="link" />
							</div>
						</div>
					</div>

					<div class="sub-techniques" v-if="technique.subtechniques?.length && technique.is_expanded">
						<div class="sub-technique is-unselectable" :class="[
							{ 'is-hidden': showSelectedItems && !subtechnique.is_selected },
							{ 'is-selected': subtechnique.is_selected }
						]" v-for="(subtechnique, subtechnique_index) in technique.subtechniques" :key="subtechnique_index" @click.exact="
							selectMultipleSubtechnique({
								tid: tactic_index,
								tnid: technique_index,
								stnid: subtechnique_index,
								refID
							})
							" @click.shift="
									selectMultipleSubtechnique({
										tid: tactic_index,
										tnid: technique_index,
										stnid: subtechnique_index,
										refID
									})
									" @click.meta="
									selectMultipleSubtechnique({
										tid: tactic_index,
										tnid: technique_index,
										stnid: subtechnique_index,
										refID
									})
									">
							{{ subtechnique.title }}
						</div>
					</div>
				</div>
				<div class="technique-wrapper is-add-technique" @click="toggleAddTechniqueSheet(tactic)">
					<div class="technique has-text-dark">
						<span class="icon">
							<icon icon="plus" />
						</span>
					</div>
				</div>
			</div>
		</div>
		<!-- Custom Technique -->
		<AddCustomTechnique :refID="refID" :tacticID="selectedTacticID" v-if="showAddTechniquesSheet"
			@close="toggleAddTechniqueSheet()" />
	</div>
</template>

<script>
import COLORS from "@/assets/js/constants.js"
import AddCustomTechnique from "@/components/AddCustomTechnique.vue"
import axios from "axios";
import { mapActions, mapGetters } from "vuex"

export default {
	data() {
		return {
			BASE_COLOR: COLORS.PALETTE.BASE,
			showAddTechniquesSheet: false,
			selectedTacticID: null,
			// searchQuery: "" // Add this line

		}
	},
	components: {
		AddCustomTechnique
	},
	props: {
		refID: {
			type: String
		}
	},
	methods: {
		...mapActions([
			"toggleSubtechnique",
			"selectSingleTechnique",
			"selectMultipleTechnique",
			"selectMultipleSubtechnique"
		]),
		// configureNavigatorData() {
		// 	let { navigatorData, refID } = this



		// 	if (!navigatorData) return
		// 	if (!refID) return

		// 	this.navigatorData[refID][0].title = refID
		// 	console.log(refID)
		// },
		async fetchNavigatorData() {
			try {
				const response = await axios.get('/user/navigatorData'); // Replace with your actual API endpoint
				console.log(response)
				this.$store.commit('setNavigatorData', response.data.navData); // Assume you're using Vuex to store data
			} catch (error) {
				console.error("Error fetching navigator data:", error);
			}
		},
		configureColorsBox(color) {
			let colorConfig = { backgroundColor: color.background_color }
			return colorConfig
		},
		getSelectedSubtechniqueCount(subtechniques) {
			if (!subtechniques?.length) return 0
			let total = subtechniques.filter(subtechnique => subtechnique.is_selected === true)
			let len = total.length

			if (len) return len
			else return 0
		},
		getTechniqueStyle(technique) {
			let { BASE_COLOR, getBaseStyle } = this

			if (!technique.is_selected) return null
			if (!technique.style) return getBaseStyle(BASE_COLOR)

			let style = {
				"--hue": technique.style.hue,
				"--saturation": technique.style.saturation,
				"--lightness": technique.style.lightness,
				"--color": technique.style.color
			}
			return style
		},
		getBaseStyle(base) {
			let { BASE_COLOR } = this
			let style = {
				"--hue": base.hue,
				"--saturation": base.saturation,
				"--lightness": base.lightness,
				"--base-hue": BASE_COLOR.hue,
				"--base-saturation": BASE_COLOR.saturation,
				"--base-lightness": BASE_COLOR.lightness,
				"--color": base.color
			}

			return style
		},
		openTechnique(technique, tactic_index) {
			let techniqueID = technique.id
			let tacticID = tactic_index < 9 ? "0" + (tactic_index + 1) : tactic_index + 1

			tacticID = tacticID.toString()

			let routeData = this.$router.resolve({ name: "TechniqueBaseView", params: { tacticID, techniqueID } })
			window.open(routeData.href, "_blank")
		},
		getTotalSelectedItems(tactic) {
			if (!tactic) return 0
			let { techniques } = tactic
			let total = 0

			techniques.forEach(technique => {
				if (technique.is_selected_active || technique.is_selected) total++
			})

			return total
		},
		toggleAddTechniqueSheet(tactic) {
			if (!tactic) {
				this.showAddTechniquesSheet = !this.showAddTechniquesSheet
				this.selectedTacticID = null
				return
			}

			this.selectedTacticID = tactic._id
			this.showAddTechniquesSheet = !this.showAddTechniquesSheet
		}
	},
	computed: {
		...mapGetters(["showSelectedItems", "navigatorData"]),
		parsedNavigatorData() {
			let { refID } = this
			return this.navigatorData[refID]
		},

	// 	filteredNavigatorData() {
	// 	const search = this.searchQuery.toLowerCase();
	// 	if (!search) return this.parsedNavigatorData;

	// 	return this.parsedNavigatorData.map(tactic => {
	// 		const filteredTechniques = tactic.techniques.filter(technique => {
	// 			const techniqueTitleMatch = technique.title.toLowerCase().includes(search);
	// 			const subtechniqueMatch = technique.subtechniques?.some(subtechnique =>
	// 				subtechnique.title.toLowerCase().includes(search)
	// 			);

	// 			return techniqueTitleMatch || subtechniqueMatch;
	// 		});

	// 		return {
	// 			...tactic,
	// 			techniques: filteredTechniques
	// 		};
	// 	}).filter(tactic => tactic.techniques.length > 0);
	// },
	},
	
	mounted() {
		this.fetchNavigatorData();
	}
}
</script>

<style lang="scss" scoped>
$h: var(--hue);
$s: var(--saturation);
$l: var(--lightness);

$base-color: hsl($h, $s, $l);

.navigator-wrapper {
	display: flex;
	gap: $gap;
	position: relative;
	width: fit-content;
}

.tactic-wrapper {
	flex-grow: 1;
	width: min-content;

	.tactic {
		display: flex;
		gap: $gap;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		padding: $gap;
		margin-bottom: $gap;

		opacity: 0.95;
		background-color: hsl($background-h, $background-l, $background-l);
		width: 100%;
		min-width: 250px;

		position: sticky;
		top: 0;
		z-index: 1;

		.tactic-title {
			color: $base-active-text-color;
			font-weight: 700;
		}

		.tactic-counter {
			color: $base-hover-text-color;
			font-size: $size-7;
		}
	}

	.techniques-wrapper {
		display: flex;
		flex-direction: column;
		gap: $gap;

		.technique-wrapper {
			position: relative;
			display: flex;
			gap: calc($gap/2);
			flex-direction: column;
			border: 2px solid $base-hover-background-color;

			&.is-add-technique {
				background-color: $base-background-color-light;
				border: 3px dotted hsl(0, 0%, 80%);

				&:hover {
					cursor: pointer;
					background-color: $base-hover-background-color;
				}

				.technique {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			&:before {
				content: "";
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 3px;
				background-color: transparent;
			}

			border-radius: calc($gap / 2);
			background-color: $base-background-color-dark;
			overflow: hidden;
			padding: $gap;

			&.is-empty {
				&:hover {
					.technique {
						color: $base-active-text-color;
					}
				}
			}

			.technique {
				min-width: 220px;
				max-width: 500px;
				text-align: left;
				border: 2px solid transparent;
				display: flex;
				flex-direction: column;
				// gap: calc($gap/2);

				.technique-item:hover:not(.is-unhoverable) {
					cursor: pointer;
					background-color: $base-hover-background-color;
				}

				.technique-item {
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					gap: $gap;
					padding: $gap;
					border-radius: calc($gap/2);

					font-size: $size-7;

					.technique-title {
						color: $base-active-text-color;
						font-weight: 600;
					}

					&.is-unhoverable {
						padding: $gap 0;
					}

					.technique-counter {
						color: $base-text-color-light;
					}

					.expand-subtechnique,
					.link {
						display: flex;
						flex: 1;
						// justify-content: space-between;
						align-items: flex-end;
						gap: $gap;
						padding: $gap;
						border-radius: calc($gap/2);

						font-size: $size-7;
						justify-content: center;
						color: hsl($h, $s, 50%);
						height: fit-content;
						border: 1px solid hsl($h, $s, 30%);
						color: hsl($h, $s, 30%);

						&:hover {
							cursor: pointer;
							background-color: $base-hover-background-color;
						}
					}

					.link {
						&.is-narrow {
							flex: 0;
						}
					}
				}

				.colors {
					display: flex;
					gap: $gap;

					.color {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 24px;
						width: 24px;

						border: 2px solid $light;
						border-radius: 100px;
					}
				}
			}

			.sub-techniques {
				display: flex;
				flex-direction: column;
				gap: $gap;

				.sub-technique {
					padding: $gap;
					border: 1px solid transparent;
					border-radius: calc($gap);
					border: 1px solid $base-hover-background-color-dark;
					border-left: 5px solid $base-hover-background-color-dark;
					background-color: $base-background-color-light;
					color: $base-active-text-color;

					&:hover {
						cursor: pointer;
						color: $base-hover-text-color-light;
						background-color: $base-hover-background-color;
					}
				}
			}
		}
	}
}

// Color selection modifiers
.technique-wrapper {
	&#technique.is-selected {
		border: 2px solid $base-color;
		// custom variables
		$h: var(--hue);
		$s: var(--saturation);
		$l: var(--lightness);
		$base-color: hsl($h, $s, $l);
		border-color: $base-color;
		background-color: hsl($h, $s, 75%);
		transition: border 170ms ease-in-out, background-color 170ms ease-in-out;

		&:before {
			content: "";
			background-color: $base-color;
		}

		.technique-item {
			.technique-counter {
				// color: $base-color;
				font-weight: 600;
			}

			.expand-subtechnique,
			.link {
				border: 1px solid hsl($h, $s, 30%);
				color: hsl($h, $s, 30%);
				background-color: transparent;

				&:hover {
					border-color: hsl(var(--hue), var(--saturation), 30%);
					background-color: hsl($h, $s, $background-l - 15);
				}
			}

			&:hover:not(.is-unhoverable) {
				background-color: hsl($h, $s, $background-l - 15);
			}
		}
	}
}

.sub-techniques {
	.sub-technique {
		&.is-selected {
			// custom variables
			$h: var(--hue);
			$s: var(--saturation);
			$l: var(--lightness);
			$base-color: hsl($h, $s, $l);

			border-color: $base-color !important;
			background-color: hsl($h, $s, 84%) !important;
			transition: border 170ms ease-in-out, background-color 170ms ease-in-out;
		}
	}
}

#technique.is-selected-active {
	// custom variables
	$h: var(--base-hue);
	$s: var(--base-saturation);
	$l: var(--base-lightness);
	$base-color: hsl($h, $s, $l);
	border: 2px dotted hsl($h, $s, 30%);
	background-color: hsl($h, $s, 90%) !important;
	transition: border 170ms ease-in-out, background-color 170ms ease-in-out;

	&.is-selected {
		display: inherit !important;
		background-color: hsl(var(--hue), var(--saturation), 90%) !important;
		border-color: hsl(var(--hue), var(--saturation), 30%) !important;
		border-style: dotted !important;

		&:before {
			content: "";
			background-color: hsl(var(--hue), var(--saturation), var(--base-lightness)) !important;
		}
	}

	&.is-hidden {
		display: inherit !important;
	}

	&:before {
		content: "";
		background-color: $base-color !important;
	}

	.technique-item {
		&:hover:not(.is-unhoverable) {
			background-color: hsl(var(--hue), var(--saturation), 80%) !important;
		}
	}
}


</style>