export default {
	activeTab: state => state.activeTab,
	tabConfig: state => state.tabConfig,
	selectedItems: state => state.selectedItems,
	navigatorData: state => state.navigatorData,
	isSidebarOpen: state => state.isSidebarOpen,
	showSelectedItems: state => state.showSelectedItems,
	tacticColorScheme: state => state.tacticColorScheme,
	selectedTechniqueInfo: state => state.selectedTechniqueInfo,
	isTechniqueEditable: state => state.isTechniqueEditable,
	isLoggedIn: state => state.isLoggedIn,
	accessToken: state => state.accessToken,
	activeLayerTitle: state => state.tabConfig[state.activeTab].title,
	sops: state => state.sops
}
