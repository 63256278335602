<template>
	<div class="color-picker">
		<div class="color-picker-body">
			<div class="color-picker-item palette" @click.prevent="toggleDropdown">
				<icon icon="palette" size="lg" />
			</div>
			<div class="color-picker-item">
				<div class="color-picker-dropdown" :class="{ 'is-active': is_active }">
					<div class="btns">
						<div class="btn-item" @click="canResetTechniqueColorScheme({ refID })">
							<div class="reset-btn"><icon icon="tint-slash" size="lg" /> Reset</div>
						</div>
					</div>
					<div class="colors">
						<div
							class="color-item"
							v-for="(color, index) in colors"
							:key="index"
							:style="{ backgroundColor: color.background_color }"
							@click="canSetTechniqueColorScheme({ color, refID })"
						></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CONSTANTS from "@/assets/js/constants.js"
import axios from "axios"
import { mapActions, mapGetters } from "vuex"

export default {
	data() {
		return {
			is_active: false,
			colors: CONSTANTS.PALETTE.DARK
		}
	},
	props: {
		refID: {
			type: String
		}
	},
	methods: {
		...mapActions(["setTechniqueColorScheme", "resetTechniqueColorScheme"]),
		toggleDropdown() {
			this.is_active = !this.is_active
		},
		canSetTechniqueColorScheme({ color, refID }) {
			this.setTechniqueColorScheme({ color, refID }).then(() => {
				let payload = { action: 1, color }
				this.syncLayerChanges(payload)
			})
		},
		canResetTechniqueColorScheme({ refID }) {
			this.resetTechniqueColorScheme({ refID }).then(() => {
				let payload = { action: 1 }
				this.syncLayerChanges(payload)
			})
		},
		syncLayerChanges({ action = 1, color = null }) {
			let { activeTab, tabConfig, selectedItems, processColors } = this
			let layerID = tabConfig[activeTab].id

			let url = `/user/layers/${layerID}/update`
			let payload = {
				layer: { action, selectedItems: processColors(selectedItems[layerID], color) }
			}

			axios.put(url, payload)
			// .then(response => {})
		},
		processColors(selectedItems, color) {
			if (!color) return selectedItems
			let result = []
			selectedItems.forEach(item => {
				result.push({ ...item, color })
			})

			return result
		}
	},
	computed: {
		...mapGetters(["activeTab", "tabConfig", "selectedItems"])
	}
}
</script>

<style lang="scss" scoped>
.color-picker {
	width: 100%;
	.color-picker-body {
		position: relative;
		height: $navigator-header-height-min;
		width: 100%;
		border-radius: calc($gap / 2);

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		color: $base-hover-text-color;
		background-color: transparent;
		border: 0;

		&:hover {
			cursor: pointer;
			color: $base-hover-text-color-light;
		}

		.color-picker-item {
			&.palette {
				height: 100%;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.color-picker-dropdown {
				display: none;
				position: absolute;
				top: calc($navigator-header-height-min + $gap);
				right: 0;
				height: 400px;
				width: 230px;
				border-radius: calc($gap / 2);
				background-color: $base-background-color-dark;
				z-index: 2;
				padding: calc($gap * 2);
				overflow: hidden;
				cursor: default;

				border: 2px solid $base-hover-background-color;

				&.is-active {
					display: flex;
					gap: calc($gap * 2);
					flex-direction: column;
				}
			}
		}
	}
}

.colors {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: $gap;

	.color-item {
		border: 1px solid white;
		height: calc($navigator-header-height-min / 1.25);
		width: calc($navigator-header-height-min / 1.25);
		min-width: calc($navigator-header-height-min / 1.25);
		max-width: calc($navigator-header-height-min / 1.25);
		border-radius: 1000px;

		background-color: transparent;

		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
	}
}

.btn-item {
	display: flex;
	flex-direction: row-reverse;
	gap: $gap;

	align-items: center;
	justify-content: center;

	width: 100%;
	min-width: $navigator-header-height-min;
	height: $navigator-header-height-min;
	border-radius: 100px;

	color: $base-hover-text-color;
	background-color: $base-background-color-light;
	box-shadow: none !important;

	user-select: none; /* standard syntax */
	-webkit-user-select: none; /* webkit (safari, chrome) browsers */
	-moz-user-select: none; /* mozilla browsers */
	-khtml-user-select: none; /* webkit (konqueror) browsers */
	-ms-user-select: none; /* IE10+ */

	&:hover {
		cursor: pointer;
		color: $base-hover-text-color-light;
		background-color: $base-hover-background-color;
	}

	&:focus {
		box-shadow: none;
	}
}
.reset-btn {
	text-align: center;
}
.btns {
	display: flex;
	flex-direction: row-reverse;
}
</style>
