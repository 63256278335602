<template>
	<div class="handles-wrapper">
		<div class="handles-head">
			<div class="header-item control">
				<div class="dropdown" :class="{ 'is-active': showHandleSuggestor }">
					<div class="dropdown-trigger">
						<div class="control is-fullwidth">
							<button class="button is-light" @click="toggleHandleSuggester()">
								<span>Select a handle</span>
								<span class="icon">
									<icon icon="angle-down" />
								</span>
							</button>
						</div>
					</div>
					<div class="dropdown-menu fade-in-down">
						<div class="dropdown-content">
							<div class="dropdown-item" v-if="fetchingHandles">
								<span class="icon">
									<span class="loader is-dark"></span>
								</span>
								<span>Fetching handles...</span>
							</div>
							<div class="dropdown-item has-text-grey">
								<span v-if="noHandlesFound">No handles found</span>
							</div>
							<div class="dropdown-item search-box" v-if="handlesList?.length && !fetchingHandles && !noHandlesFound">
								<input
									type="text"
									class="input has-text-weight-semibold"
									ref="handleSuggesterInput"
									placeholder="Search handles"
									v-model="searchInput"
									autocomplete="off"
									auto
								/>
							</div>
							<div class="handles-list" v-if="handlesList?.length">
								<a
									class="dropdown-item"
									v-for="(handle, index) in filteredHandles"
									:key="index"
									@click="addHandle(handle, true)"
								>
									{{ handle.handleTitle }}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="handles-body">
			<div class="user-handles">
				<div class="user-handle-item" v-for="(handleType, index) in Object.keys(handlesInfo)" :key="index">
					<header>
						<div class="header-left">
							<div class="header-item">
								{{ parseHandleName(handleType) }}
							</div>
							<div class="header-item action-item" @click="addHandle({ handleType: handleType })">
								<span class="icon">
									<icon icon="plus" v-if="handlesInfo[handleType].length" />
								</span>
							</div>
						</div>
						<div class="header-right actions">
							<div class="action-item" @click="toggleAllTables(handleType)">
								<span class="icon">
									<icon icon="minus" v-if="expandTables[handleType]" />
									<icon icon="plus" v-else />
								</span>
								<span>{{ expandTables[handleType] ? "Collapse all" : "Expand all" }}</span>
							</div>
						</div>
					</header>

					<div class="handle-wrapper">
						<div class="handle-item" v-for="(item, item_index) in handlesInfo[handleType]" :key="item">
							<div class="handle-head">
								<div class="input-wrapper">
									<input
										type="text"
										class="input is-small"
										:ref="`handleItem_${handleType}_${item_index}`"
										v-model="handlesInfo[handleType][item_index].handleKey"
										:disabled="item.lookupHandleId"
										@keyup.enter.prevent="fetchHandleDetails(handleType, item, item_index)"
										:placeholder="`Enter ${parseHandlePlaceholder(handleType)}`"
									/>
								</div>
								<button
									tabindex="-1"
									class="button is-light"
									@click="fetchHandleDetails(handleType, item, item_index)"
									v-if="!item.handleValue"
								>
									<span class="icon">
										<span class="loader is-dark" v-if="item?.loading"></span>
										<icon icon="magnifying-glass" v-else />
									</span>
								</button>

								<button
									tabindex="-1"
									class="button is-light is-danger"
									@click="removeHandle(handleType, item, item_index)"
								>
									<span class="icon">
										<span class="loader is-dark" v-if="item?.deleting"></span>
										<icon icon="trash" v-else />
									</span>
								</button>
							</div>

							<div class="handle-body" v-if="item.lookupHandleId">
								<component :is="handleType" :handle="item.handleValue" :ref="`${handleType}_${item_index}`" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="handles-foot">
			<button class="button is-light" @click="saveHandles" :class="{ 'is-loading': savingHandles }">
				Save Handles
			</button>
		</div>
	</div>
</template>

<script>
import axios from "axios"
import IP_ADDRESS from "@/components/handles/IP_ADDRESS.vue"
import MAC_ADDRESS from "@/components/handles/MAC_ADDRESS.vue"

export default {
	components: {
		IP_ADDRESS,
		MAC_ADDRESS
	},
	data() {
		return {
			showHandleSuggestor: false,
			fetchingHandles: true,
			noHandlesFound: false,
			handlesList: [],
			handlesInfo: {},
			searchInput: null,
			savingHandles: false,
			expandTables: {
				IP_ADDRESS: false,
				MAC_ADDRESS: false
			}
		}
	},
	props: {
		refID: {
			type: String,
			required: true
		}
	},
	methods: {
		focus() {
			console.log("")
			// this.$nextTick(() => this.$refs["handleSuggesterInput"].focus())
		},
		toggleHandleSuggester() {
			this.showHandleSuggestor = !this.showHandleSuggestor

			let showSuggestiondropdown = this.showHandleSuggestor && this.handlesList.length
			if (showSuggestiondropdown) this.$nextTick(() => this.$refs["handleSuggesterInput"].focus())
		},
		fetchAllHandles() {
			let url = "/user/handles-list"

			this.fetchingHandles = true

			axios
				.get(url)
				.then(response => {
					if (response.data.success) this.handlesList = response.data.handles
					this.fetchingHandles = false

					if (!response.data.success) this.noHandlesFound = true
				})
				.catch(() => {
					this.fetchingHandles = false
					this.noHandlesFound = true
				})
		},
		clearSearchInput() {
			this.searchInput = null
		},
		addHandle(handle, focus = false) {
			let { handleType } = handle

			if (!handleType) return
			if (!this.handlesInfo[handleType]?.length) this.handlesInfo[handleType] = []

			this.handlesInfo[handleType].push({ ...handle })
			this.clearSearchInput()

			if (focus) this.toggleHandleSuggester()

			// scroll top the new element
			let ref = `handleItem_${handleType}_${this.handlesInfo[handleType].length - 1}`
			// this.$refs[ref][0].scrollIntoView({ behavior: "smooth" })

			this.$nextTick(() => this.$refs[ref][0].focus())
		},
		parseHandleName(handleType) {
			let selectedHandle = this.handlesList.find(handle => handle.handleType == handleType)
			return selectedHandle?.handleTitle || "-"
		},
		parseHandlePlaceholder(handleType) {
			let handle = this.handlesList.find(item => item.handleType == handleType)

			return handle?.handleTitle || "-"
		},
		fetchHandleDetails(handleType, item, item_index) {
			let { handleValue } = item
			let { handleKey } = this.handlesInfo[handleType][item_index]

			if (handleValue) return
			if (!handleKey || !handleKey.length) return

			let url = "/user/handles/search"
			let payload = {
				name: item.handleType,
				value: this.handlesInfo[handleType][item_index].handleKey
			}

			this.handlesInfo[handleType][item_index] = {
				...this.handlesInfo[handleType][item_index],
				loading: true
			}

			axios
				.post(url, payload)
				.then(response => {
					this.handlesInfo[handleType][item_index].loading = false
					this.handlesInfo[handleType][item_index] = {
						...this.handlesInfo[handleType][item_index],
						...response.data
					}
				})
				.catch(() => {
					this.handlesInfo[handleType][item_index].loading = false
				})
		},
		removeHandle(handleType, handle, handleIndex) {
			let { lookupHandleId } = handle
			let url = "/user/handles-info/remove"
			let payload = {
				layerId: this.refID,
				lookupHandleId: lookupHandleId
			}

			if (!lookupHandleId) return this.removeHandleLocally(handleType, handleIndex)

			this.handlesInfo[handleType][handleIndex].deleting = true

			if (!handle.lookupHandleId) return this.removeHandleLocally(handleType, handleIndex)

			axios.post(url, payload).then(response => {
				if (response.data.success) this.removeHandleLocally(handleType, handleIndex)
			})
		},
		removeHandleLocally(handleType, handleIndex) {
			this.handlesInfo[handleType].splice(handleIndex, 1)

			if (!this.handlesInfo[handleType].length) delete this.handlesInfo[handleType]

			// this.$nextTick(() => {
			// 	this.$refs[`handleItem_${handleType}_${handleIndex}`][0].focus()
			// })
		},
		saveHandles() {
			let url = "/user/handles-info/save"
			let payload = {
				layerID: this.refID,
				handlesInfo: this.processHandles()
			}

			this.savingHandles = true

			console.log("SAVEHANDLES:: ", { ...payload })

			axios
				.post(url, payload)
				.then(response => {
					console.log("SUCCESS: ", response.data.success)
					this.savingHandles = false
				})
				.catch(() => {
					this.savingHandles = false
				})
		},
		processHandles() {
			let handlesInfo = JSON.parse(JSON.stringify(this.handlesInfo))

			Object.keys(handlesInfo).forEach(handleType => {
				for (let i in handlesInfo[handleType]) {
					const { lookupHandleId } = handlesInfo[handleType][i]

					if (!lookupHandleId) {
						console.log("UNDEFINED")
						delete handlesInfo[handleType][i]
						continue
					}
					handlesInfo[handleType][i] = { lookupHandleId }
				}
			})

			console.log("LEN::", handlesInfo.IP_ADDRESS.length)
			return handlesInfo
		},
		fetchHandlesInfo() {
			let url = `/user/handles-info/${this.refID}`
			axios.get(url).then(response => {
				if (response.data.success) {
					let isValid = !!Object.keys(response.data).length
					if (isValid) this.handlesInfo = response.data.handlesInfo
				}
			})
		},
		toggleAllTables(handleType) {
			this.handlesInfo[handleType].forEach((_, index) => {
				this.$refs[`${handleType}_${index}`][0].toggleTableDetails()
			})

			this.$nextTick(() => (this.expandTables[handleType] = !this.expandTables[handleType]))
		}
	},
	computed: {
		filteredHandles() {
			if (!this.searchInput) return this.handlesList

			const searchTerm = this.searchInput.toLowerCase()
			return this.handlesList.filter(handle => handle.handleTitle.toLowerCase().includes(searchTerm))
		}
	},
	mounted() {
		this.fetchAllHandles()
		this.fetchHandlesInfo()
	}
}
</script>

<style lang="scss" scoped>
.handles-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;

	.handles-head {
		z-index: 5;
	}
	.handles-body {
		height: 100%;
		flex: 1;
		overflow-y: scroll;
		.user-handles {
			display: flex;
			gap: $gap;
			flex-direction: column;
			.user-handle-item {
				display: flex;
				flex-direction: column;
				gap: $gap;
				position: relative;

				padding-bottom: calc($gap * 3);
				border-bottom: 2px solid hsl(0, 0%, 86%);
				&:last-child {
					border-bottom: none;
				}

				header {
					font-size: 15px;
					font-weight: 700;
					color: hsl(0, 0%, 40%);
					padding: $gap 0;

					display: flex;
					align-items: flex-end;
					justify-content: space-between;

					border-bottom: 1px solid hsl(0, 0%, 86%);
					padding-bottom: $gap;

					position: sticky;
					top: 0;

					background-color: white;
					z-index: 2;

					.header-left {
						display: flex;
						gap: $gap;

						.action-item {
							display: flex;
							justify-content: center;
							align-items: center;

							border-radius: $gap;
							background-color: hsl(0, 0%, 93%);

							&:hover {
								cursor: pointer;
								background-color: hsl(0, 0%, 90%);
							}
						}
					}

					.header-right {
						display: flex;
						gap: calc($gap/2);
						align-items: center;
						justify-content: center;
						&.actions {
							.action-item {
								display: flex;
								gap: calc($gap/2);
								align-items: center;
								justify-content: center;
								color: hsl(0, 0%, 50%);

								font-size: 0.8rem;
								.icon {
									width: fit-content;
								}
								&:hover {
									color: hsl(0, 0%, 40%);
									cursor: pointer;
									text-decoration: underline;
								}
							}
						}
					}
				}

				.handle-wrapper {
					display: flex;
					flex-direction: column;
					gap: ($gap * 2);

					.handle-item {
						border-radius: calc($gap/2);

						display: flex;
						flex-direction: column;
						gap: $gap;

						border: 1px solid hsl(0, 0%, 86%);
						border-radius: $gap;
						padding: $gap;

						.handle-head {
							display: flex;
							gap: $gap;
							justify-content: space-between;
							align-items: center;
							.input-wrapper {
								position: relative;

								display: flex;
								justify-content: center;
								align-items: center;
								width: 100%;
								background-color: transparent;
								border-color: hsl(0, 0%, 90%);

								input.input {
									background-color: hsl(0, 0%, 96%);
									border: none;
									box-shadow: none;
									flex: 1;
									height: 40px;
									font-weight: 600;
									color: hsl(0, 0%, 25%);

									&:disabled {
										color: hsl(0, 0%, 65%);
										// background-color: hsl(0, 0%, 92%);
									}
								}

								.icon {
									position: absolute;
									right: 0;
									background-color: hsl(0, 0%, 92%);
								}
							}

							button.button {
								flex: 0;
								width: fit-content;
								border: none !important;
							}
						}
					}
				}
			}
		}
	}

	.handles-foot {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: $gap;
		z-index: 5;

		button.button,
		button.button:focus {
			font-size: 0.9rem;
			width: 100%;
			color: hsl(0, 0%, 50%) !important;
			background-color: hsl(0, 0%, 94%);
			font-weight: 600;

			&.is-loading,
			&:hover {
				background-color: hsl(0, 0%, 91%);
			}
		}
	}
}

.dropdown {
	width: 100%;
	.dropdown-trigger {
		width: 100%;

		.button,
		.button:focus {
			width: 100%;
			justify-content: space-between;
			color: $base-text-color !important;
			font-weight: 600;
			font-size: 0.85rem;
		}
	}

	.dropdown-menu {
		width: 100%;
		padding-top: $gap;
		.dropdown-content {
			border: 2px solid hsl(0deg, 0%, 96%);
			padding-top: 0;

			.handles-list {
				overflow-y: auto;
				max-height: 50vh;
				height: fit-content;
			}
			a.dropdown-item {
				padding-right: calc($gap * 2);
				&.is-active {
					background-color: $base-background-color-light;
					color: $base-text-color-darker;
				}

				color: $base-text-color-light;
			}

			a.dropdown-item:not(.search-box) {
				padding-left: calc($gap * 3.5);
			}

			.dropdown-item.search-box {
				input.input {
					background-color: hsl(0, 0%, 96%);
					box-shadow: none;
					border: 0;
					color: hsl(0, 0%, 50%);
					font-weight: 500;

					&:focus {
						box-shadow: none;
						border: 0;
					}
				}
			}
		}
	}
}
</style>
