import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
import WelcomeSheetBase from "../components/WelcomeSheet/WelcomeSheetBase.vue"
// import store from "../store/store.js";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/welcome",
		name: "WelcomeSheetBase",
		component: WelcomeSheetBase,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/signup",
		name: "Signup",
		component: () => import(/* webpackChunkName: "signup" */ "../views/Signup.vue")
	},
	{
		path: "/navigator/:pathID?",
		name: "NavigatorBase",
		component: () => import(/* webpackChunkName: "navigator-base" */ "../views/navigator/NavigatorBase.vue")
	},
	{
		path: "/about",
		name: "About",
		component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
	},
	{
		path: "/standard-procedures",
		name: "SOPsBase",
		component: () => import(/* webpackChunkName: "about" */ "../views/SOPs.vue")
	},
	{
		path: "/standard-procedures",
		name: "SOPs",
		component: () => import(/* webpackChunkName: "technique-base-view" */ "../views/SOPsBaseView.vue"),
		children: [
			{
				path: "list",
				name: "SOPsList",
				component: () => import(/* webpackChunkName: "technique-details" */ "../views/SOPsList.vue"),
				meta: {
					title: "Standard Operating Procedures"
				}
			},
			{
				path: "/:SOPID?/view",
				name: "SOPsView",
				component: () => import(/* webpackChunkName: "technique-details" */ "../views/SOPsView.vue"),
				meta: {
					title: "Standard Operating Procedures"
				}
			},
			{
				path: "create",
				name: "SOPsAdd",
				component: () => import(/* webpackChunkName: "technique-details" */ "../views/SOPs.vue"),
				meta: {
					title: "Standard Operating Procedures"
				}
			}
		]
	},
	{
		path: "/tactics/:tacticID",
		name: "Tactics",
		component: () => import(/* webpackChunkName: "technique-base-view" */ "../views/TacticsBaseView.vue"),
		children: [
			{
				path: "techniques/:techniqueID",
				name: "TechniqueBaseView",
				component: () => import(/* webpackChunkName: "technique-details" */ "../views/TechniqueBaseView.vue"),
				meta: {
					title: "Technique Details"
				}
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

// Set Browser tab title on each router view load
router.beforeEach((to, from, next) => {
	let isAuthenticated = localStorage.getItem("isLoggedIn")
	let documentTitle = to.meta.title
	document.title = documentTitle ? documentTitle : "Crime Navigator"

	// If the user is authenticated and tries to visit the root path, redirect to the Welcome page
	if (isAuthenticated && to.path === "/") {
		next({ name: "WelcomeSheetBase" })
	}

	// If the route requires authentication and the user is not authenticated, redirect to the login page
	else if (to.meta.requiresAuth && !isAuthenticated) {
		next({ name: "Home" })
	} else {
		next() // Continue navigation
	}
})

export default router
