<template>
	<div class="is-error" v-if="!isLayerMergeable">
		<div class="error-title">
			<icon icon="exclamation-triangle" />
			<p>Create one or more layers to continue</p>
		</div>
		<p class="error-subtitle">Merging requires a minimum of 2 layers to function</p>
	</div>
	<div class="merge-layers" v-else>
		<div class="merge-layers-title">
			<div class="primary">
				<div>Select and prioritise layers</div>
				<div class="buttons">
					<div class="button is-hoverable" @click="selectAllLayers">Select all</div>
					<div class="button" @click="deselectAllLayers">Deselect all</div>
				</div>
			</div>
			<div class="secondary">Select 2 or more layers to continue</div>
		</div>

		<draggable class="layers-wrapper" ghost-class="ghost" v-model="activeLayers" item-key="id" animation="200">
			<template #item="{ element, index }">
				<div
					class="layer"
					@click="toggleSelectedLayer(index)"
					@mouseenter="element.hovering = true"
					@mouseleave="element.hovering = false"
				>
					<span class="icon" :class="element?.selected ? 'has-text-success' : 'has-text-danger'">
						<icon icon="check-circle" v-if="element?.selected" />
						<icon icon="times" v-else />
					</span>
					<div class="layer-title">
						<div>{{ element.title }}</div>
						<div class="hover-message is-unselectable is-size-7 is-uppercase" v-if="element.hovering">
							Click to {{ element.selected ? "deselect" : "select" }}
						</div>
					</div>
				</div>
			</template>
		</draggable>

		<button class="button merge-button" :disabled="!isMergeConfigValid()" @click="processMergeLayers">Merge</button>
	</div>
</template>

<script>
import draggable from "vuedraggable"
import { mapActions, mapGetters } from "vuex"

export default {
	props: {
		refID: {
			type: [String]
		}
	},
	components: {
		draggable
	},
	data() {
		return {
			activeLayers: [],
			enabled: true,
			dragging: false
		}
	},
	methods: {
		...mapActions([
			"setPredefinedNavigatorData",
			"selectSingleTechnique",
			"selectMultipleSubtechnique",
			"createNewLayer"
		]),
		parseActiveLayers() {
			let { tabConfig } = this
			let activeLayers = tabConfig.filter(tab => tab.active === true)

			this.activeLayers = JSON.parse(JSON.stringify(activeLayers))
			this.selectAllLayers()
		},
		processMergeLayers() {
			let { validateMergeConfig } = this
			let mergeConfig = this.activeLayers.filter(tab => tab.selected === true)

			validateMergeConfig(mergeConfig)
		},
		validateMergeConfig(mergeConfig) {
			let { parseLayers, isMergeConfigValid } = this
			let isConfigValid = isMergeConfigValid()

			if (!isConfigValid) return console.log("ERROR LEN < 2")

			parseLayers(mergeConfig)
		},
		parseLayers(mergeConfig) {
			let { navigatorData, isMergeConfigValid, mergeLayers, processCustomNavigatorData } = this
			let isConfigValid = isMergeConfigValid()
			let len = mergeConfig.length
			let layers = []
			let temp

			if (!isConfigValid) return console.log("ERROR LEN < 2")

			for (let i = 0; i < len; i++) {
				let id = mergeConfig[i].id
				let el = navigatorData[id]
				layers.push(el)
			}

			for (let i = 0; i < len; i++) {
				if (i === 0) temp = layers[i]
				if (i < len && i > 0) temp = mergeLayers(temp, layers[i])
			}

			let finalData = temp
			let oldTabID = this.tabConfig[this.activeTab].id
			let title = this.tabConfig[this.activeTab].title

			this.createNewLayer(title).then(response => {
				let tabID = response

				let payload = {
					tabID: tabID,
					data: finalData,
					active: true,
					title: title,
					oldTabID: oldTabID
				}

				processCustomNavigatorData(payload)
			})
		},
		mergeLayers(baseLayer, inputLayer) {
			let { validateColorsSet, validateColors } = this
			let result = JSON.parse(JSON.stringify(baseLayer))

			baseLayer.forEach((tactic, tt_i) => {
				tactic.techniques.forEach((item, tn_i) => {
					let baseSelectedState = item.is_selected
					let inputStyle = inputLayer[tt_i].techniques[tn_i].style
					let inputSelectedState = inputLayer[tt_i].techniques[tn_i].is_selected
					let inputExpandededState = inputLayer[tt_i].techniques[tn_i].is_expanded
					let inputSubtechniques = inputLayer[tt_i].techniques[tn_i].subtechniques
					let inputColors = inputLayer[tt_i].techniques[tn_i].colors
					let baseColors = item.colors

					if (item.style) {
						let baseStyle = item.style

						result = validateColorsSet(result, tt_i, tn_i)

						if (inputStyle) {
							result[tt_i].techniques[tn_i].colors.add(baseStyle)

							let isEqual = JSON.stringify(inputStyle) === JSON.stringify(baseStyle)
							if (!isEqual) result[tt_i].techniques[tn_i].colors.add(inputStyle)

							baseColors = validateColors(baseColors)

							let colors = result[tt_i].techniques[tn_i].colors
							result[tt_i].techniques[tn_i].colors = new Set([...baseColors, ...colors])
						}
					}

					// Merge items from inputLayer, that are unavailable in baseLayer
					if (inputLayer[tt_i].techniques[tn_i]?.is_selected) {
						result[tt_i].techniques[tn_i].is_selected = inputSelectedState
						result[tt_i].techniques[tn_i].is_expanded = inputExpandededState
					}

					if (inputLayer[tt_i].techniques[tn_i]?.is_selected && !baseSelectedState)
						result[tt_i].techniques[tn_i].style = inputStyle

					if (baseSelectedState) result[tt_i].techniques[tn_i].style = item.style

					if (inputSelectedState && inputSubtechniques && inputSubtechniques.length) {
						inputSubtechniques.forEach((item, stn_i) => {
							let is_selected = item.is_selected
							if (is_selected) result[tt_i].techniques[tn_i].subtechniques[stn_i] = { ...item }
						})
					}

					if (!validateColors(baseColors).size && validateColors(inputColors).size) {
						let colors = validateColors(result[tt_i].techniques[tn_i].colors)
						result[tt_i].techniques[tn_i].colors = new Set([...colors, ...inputColors])
					}
				})
			})

			return result
		},
		validateColorsSet(result, tt_i, tn_i) {
			if (result[tt_i].techniques[tn_i].colors instanceof Set === false) {
				result[tt_i].techniques[tn_i].colors = new Set()
			}

			return result
		},
		validateColors(baseColor) {
			if (baseColor instanceof Set === false) baseColor = new Set()

			return baseColor
		},
		toggleSelectedLayer(index) {
			this.activeLayers[index].selected = !this.activeLayers[index].selected
		},
		selectAllLayers() {
			this.activeLayers.forEach(layer => (layer.selected = true))
		},
		deselectAllLayers() {
			this.activeLayers.forEach(layer => (layer.selected = false))
		},
		isMergeConfigValid() {
			let result = false
			let count = this.activeLayers.filter(tab => tab.selected === true).length

			if (count >= 2) result = true

			return result
		},

		// select path
		processCustomNavigatorData(payload) {
			this.setPredefinedNavigatorData(payload)
		},
		selectPath(selection) {
			let { resolveTacticID, resolveTechniqueID, procesSubtechniques } = this
			let pathArr = selection.data
			let refID = this.tabConfig[this.activeTab].id

			pathArr.forEach(path => {
				let tacticID = path.tid
				let techniqueID = path.tnid
				let subtechniques = path.stnid

				let tid = resolveTacticID(tacticID)
				let tnid = resolveTechniqueID(tacticID, techniqueID, subtechniques)

				let payload = { refID, tid, tnid }

				this.selectSingleTechnique(payload).then(() => {
					if (subtechniques?.length) {
						procesSubtechniques({ subtechniques, ...payload })
					}
				})
			})
		},
		resolveTacticID(tacticID) {
			let tid = parseInt(tacticID) - 1
			return tid
		},
		resolveTechniqueID(tacticID, techniqueID) {
			let { navigatorData, resolveTacticID } = this
			let tid = resolveTacticID(tacticID)
			let tnid
			let refID = this.tabConfig[this.activeTab].id

			// navigatorData[refID][tid].techniques.findIndex(technique => {
			// })
			tnid = navigatorData[refID][tid].techniques.findIndex(technique => technique.id == techniqueID)

			return tnid
		},
		procesSubtechniques({ subtechniques, tid, tnid }) {
			let { selectMultipleSubtechnique, resolveSubtechniqueID } = this
			let refID = this.tabConfig[this.activeTab].id

			subtechniques.forEach(subtechniqueID => {
				let stnid = resolveSubtechniqueID({ subtechniqueID })
				selectMultipleSubtechnique({ refID, tid, tnid, stnid })
			})
		},
		resolveSubtechniqueID({ subtechniqueID }) {
			let stnid = parseInt(subtechniqueID) - 1
			return stnid
		}
	},
	computed: {
		...mapGetters(["tabConfig", "navigatorData", "activeTab"]),
		isLayerMergeable() {
			let { tabConfig } = this
			let result = false
			let count = tabConfig.filter(tab => tab.active === true).length

			if (count >= 2) result = true

			return result
		}
	},
	mounted() {
		this.parseActiveLayers()
	}
}
</script>

<style lang="scss" scoped>
.merge-layers {
	display: flex;
	flex-direction: column;
	gap: calc($gap * 2);
	height: fit-content;
	padding: calc($gap/2);

	.merge-layers-title {
		.primary {
			font-size: 15px;
			font-weight: 800;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.buttons {
				.button {
					font-size: 12px;
					font-weight: 600;
					background-color: $base-hover-background-color;
					border: 0;
					color: $base-text-color-light;

					&:hover {
						color: $base-hover-text-color;
						background-color: $base-hover-background-color-light;
					}
				}
			}
		}
		.secondary {
			font-size: 12px;
		}
	}

	.layers-wrapper {
		display: flex;
		flex-direction: column;
		gap: $gap;
		overflow: hidden;
		.layer {
			display: flex;
			align-items: center;

			width: 100%;
			padding: $gap;
			border-radius: calc($gap/2);
			background-color: $base-hover-background-color;

			.layer-title {
				display: flex;
				justify-content: space-between;
				width: 100%;
				.hover-message {
					font-size: $size-7;
					text-transform: uppercase;
					pointer-events: none;
					color: $base-text-color;
				}
			}

			&:hover:not(:active) {
				cursor: move; /* fallback if grab cursor is unsupported */
				cursor: grab;
				cursor: -moz-grab;
				cursor: -webkit-grab;
				background-color: $base-hover-background-color-light;
			}

			&:active {
				cursor: grabbing;
				cursor: -moz-grabbing;
				cursor: -webkit-grabbing;
				.hover-message {
					display: none;
				}
			}
		}
	}
}

.is-error {
	width: 100%;
	display: flex;
	flex-direction: column;

	.error-title {
		gap: $gap;
		display: flex;
		align-items: center;
		color: hsl(0, 50%, 60%);
		font-weight: 600;
	}

	.error-subtitle {
		font-size: 12px;
		font-weight: 500;
	}
}

.merge-button {
	background-color: $base-hover-background-color-light;
	border: 0;
	color: $base-text-color-light;
	transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

	&:hover {
		color: $base-hover-text-color-light;
		background-color: $base-active-background-color-light;
	}
}

button.merge-button[disabled] {
	opacity: 0.5;
	pointer-events: none;
	background-color: $base-hover-background-color;
	transition: background-color 150ms ease-in-out;
}

.ghost {
	opacity: 0.4;
}
</style>
