<template>
	<div class="search-box">
		<input
			ref="searchInput"
			class="input"
			type="text"
			placeholder="Search a technique"
			v-model="searchInput"
			@input="filterSearchData"
		/>
	</div>
	<div class="search-body-item" v-if="filteredSearchData?.length">
		<div v-for="(technique, index) in filteredSearchData" :key="index">
			<div class="technique">
				<div class="technique-header">
					<div class="technique-title">{{ technique.title }}</div>
					<div class="technique-count" v-if="technique.path?.length > 1">
						<span class="tag"> {{ technique.path?.length }} Path(s) </span>
					</div>
				</div>
				<div class="technique-body" v-if="technique.path?.length">
					<div class="technique-path" v-for="(path, path_index) in technique.path" :key="path_index">
						<div class="technique-path-header">
							<div class="technique-path-title">
								{{ path.title }}
							</div>
							<div class="technique-path-buttons">
								<div class="button" @click="selectPath(path)">Select</div>
								<!-- <div class="button">Deselect</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import validationData from "@/assets/validationData.json"

import { mapActions, mapGetters } from "vuex"
export default {
	data() {
		return {
			searchInput: "",
			validationData: validationData,
			filteredSearchData: null
		}
	},
	props: {
		refID: {
			type: String,
			required: true
		}
	},
	methods: {
		...mapActions(["toggleSearchBox", "selectSingleTechnique", "selectMultipleSubtechnique"]),
		focus() {
			let { isSidebarOpen } = this
			let ref = this.$refs.searchInput

			if (!isSidebarOpen) return

			ref.focus()
		},
		close() {
			this.toggleSearchBox()
		},
		filterSearchData() {
			let { validationData, searchInput, resetFilterSearchData } = this

			if (!searchInput.length) resetFilterSearchData()
			if (searchInput.length < 3) return

			let result = validationData.filter(item => item.title.toLowerCase().includes(searchInput.toLowerCase()))

			this.filteredSearchData = result
		},
		resetFilterSearchData() {
			this.filteredSearchData = null
		},
		selectPath(selection) {
			let { refID, resolveTacticID, resolveTechniqueID, procesSubtechniques } = this
			let pathArr = selection.path

			pathArr.forEach(path => {
				let tacticID = path.tid
				let techniqueID = path.tnid
				let subtechniques = path.stnid

				let tid = resolveTacticID(tacticID)
				let tnid = resolveTechniqueID(tacticID, techniqueID, subtechniques)

				let payload = { refID, tid, tnid }

				this.selectSingleTechnique(payload).then(() => {
					if (subtechniques?.length) {
						procesSubtechniques({ subtechniques, ...payload })
					}
				})
			})
		},
		resolveTacticID(tacticID) {
			let tid = parseInt(tacticID) - 1
			return tid
		},
		resolveTechniqueID(tacticID, techniqueID) {
			let { navigatorData, refID, resolveTacticID } = this
			let tid = resolveTacticID(tacticID)
			let tnid

			tnid = navigatorData[refID][tid].techniques.findIndex(technique => technique.id == techniqueID)

			return tnid
		},
		procesSubtechniques({ subtechniques, tid, tnid }) {
			let { refID, selectMultipleSubtechnique, resolveSubtechniqueID } = this

			subtechniques.forEach(subtechniqueID => {
				let stnid = resolveSubtechniqueID({ subtechniqueID })
				selectMultipleSubtechnique({ refID, tid, tnid, stnid })
			})
		},
		resolveSubtechniqueID({ subtechniqueID }) {
			let stnid = parseInt(subtechniqueID) - 1
			return stnid
		}
	},
	computed: {
		...mapGetters(["isSidebarOpen", "navigatorData", "tabConfig", "activeTab"])
	}
}
</script>

<style lang="scss" scoped>
.search-box {
	width: 100%;
	height: $navigator-header-height;
	input.input {
		outline: 0;
		box-shadow: 0;
		background-color: $base-hover-background-color;
		border: 0;
		color: $base-active-text-color;

		&::placeholder {
			color: $base-text-color;
			font-size: 14px;
		}

		&:hover {
			background-color: $base-hover-background-color-light;
		}

		&:focus {
			border: 0;
			box-shadow: none;
			background-color: $base-hover-background-color-light;
		}
	}
}

.search-body-item {
	height: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	gap: calc($gap * 1.5);

	.technique {
		display: flex;
		flex-direction: column;
		// gap: $gap;

		background-color: $base-hover-background-color-light;
		border-radius: $gap;
		overflow: hidden;

		.technique-header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			padding: $gap calc($gap * 1.5);
			background-color: $base-hover-background-color-light;
			border-bottom: 1px solid $base-hover-background-color-light;
			// border-bottom: 2px solid $base-hover-background-color;

			.tag {
				background-color: $base-hover-background-color-light;
				color: $base-active-text-color;
				font-size: 12px;
			}

			.technique-title {
				font-size: 15px;
				font-weight: 700;
				color: $base-active-text-color;
			}
		}

		.technique-body {
			.technique-path {
				&:hover {
					background-color: $base-background-color-light;

					.technique-path-buttons {
						.button {
							background-color: $base-hover-background-color-light !important;
							// transition: background-color 150ms ease-in;
							&:hover {
								background-color: $base-hover-background-color !important;
							}
						}
					}
				}
				padding: $gap calc($gap * 1.5);

				.technique-path-header {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.technique-path-title {
						font-size: 13px;
						color: $base-hover-text-color;
					}

					.technique-path-buttons {
						display: flex;
						gap: calc($gap/2);
						.button {
							background-color: $base-active-background-color-light;
							color: $base-hover-text-color;
							font-size: 12px;
							border: 0;
							outline: none;
							box-shadow: none;
							&:hover {
								// color: $base-active-text-color;
								background-color: $base-hover-background-color;
							}
						}
					}
				}
			}
		}
	}
}
</style>
