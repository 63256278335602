import router from "../router"
import axios from "axios"

const randomKeygen = () => {
	return Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, "")
		.substring(0, 7)
}

export default {
	fetchNavigatorData({ commit }) {
		let url = 'user/navigatorData'

		axios.get(url).then((response) => {

			if (!response.data.success) return

			let navigatorData = response.data.navData;
			commit('SET_GLOABAL_NAVIGATOR_DATA', navigatorData)

			//  set in localstorage
			localStorage.setItem("navigatorData", JSON.stringify(navigatorData))

		})
	},
	addNewTab({ commit, state }, unsetPathID = false) {
		let { length } = state.tabConfig
		let { title } = state.baseTabConfig

		let newTitle = title + " " + length
		let customTitle = length < 1 ? title : newTitle

		let tabConfig = {
			...state.baseTabConfig,
			id: randomKeygen(),
			title: customTitle,
			unsetPathID: unsetPathID
		}

		commit("ADD_NEW_TAB", tabConfig)
		return tabConfig;
		// dispatch("setNavigatorData", layerID)
	},
	async addCustomTab({ commit, state }, { tabID, title }) {
		// let { length } = state.tabConfig
		let tabConfig = {
			...state.baseTabConfig,
			id: tabID,
			title: title
		}

		commit("ADD_NEW_TAB", tabConfig)
	},
	setNavigatorData({ commit, dispatch, getters }, tabID) {

		let { tabConfig } = getters

		if (tabID === 0) tabID = tabConfig[tabConfig.length - 1].id

		let index = tabConfig.findIndex(tab => tab.id === tabID)
		let { title } = getters.tabConfig[index]
		commit("SET_TAB_LOADING_STATE", { index, isLoading: true })

		// Create new layer on server
		dispatch("createNewLayer", title).then(response => {
			let newTabID = response

			if (!newTabID) return
			let payload = {
				tabID: newTabID,
				index: index
			}

			commit("SET_TABID", payload)
			commit("SET_NAVIGATOR_DATA", newTabID)
			commit("SET_TAB_ACTIVE_STATE", index)

			dispatch("toggleShowSelectedItems")


		})
			.then(() => {
				commit("SET_TAB_LOADING_STATE", { index, isLoading: false })
			})
			.catch(err => {
				console.log("Error: ", err)
				commit("SET_TAB_LOADING_STATE", { index, isLoading: false })
			})

	},
	setCustomNavigatorData({ commit, getters }, { tabID, oldTabID, title }) {
		if (!tabID) tabID = randomKeygen()

		let { tabConfig } = getters
		let index = tabConfig.findIndex(item => item.id == oldTabID)

		commit("SET_TABID", { tabID, oldTabID, index })
		commit("SET_TAB_TITLE", title)
		commit("SET_CUSTOM_NAVIGATOR_DATA", { tabID })
		commit("SET_TAB_ACTIVE_STATE", index)
	},
	setPredefinedNavigatorData({ commit, getters }, payload) {
		let { tabID, oldTabID, title } = payload
		if (!tabID) tabID = randomKeygen()

		let { tabConfig } = getters
		let index = tabConfig.findIndex(item => item.id == oldTabID)
		let data = payload.data

		commit("SET_TABID", { tabID, oldTabID, index })
		commit("SET_TAB_TITLE", title)
		commit("SET_PREDEFINED_NAVIGATOR_DATA", { data, tabID })
		commit("SET_TAB_ACTIVE_STATE", index)

	},
	closeTab({ commit, dispatch, getters }, i) {
		let { length } = getters.tabConfig
		if (length === 1) return

		// find refID 
		let refID = getters.tabConfig[i].id

		// delete target array from navigatorData and selectedItems
		commit("DELETE_NAVIGATOR_DATA", refID)
		commit("DELETE_SELECTED_ITEMS", refID)

		commit("CLOSE_TAB", i)
		if (getters.activeTab == i) dispatch("setActiveTab", i - 1)
		if (getters.activeTab > i) dispatch("setActiveTab", getters.activeTab - 1)


	},
	setActiveTab({ commit, getters }, len = null) {
		if (len == null) len = getters.tabConfig.length - 1
		commit("SET_ACTIVE_TAB", len)
	},
	toggleShowSelectedItems({ commit }) {
		commit("TOGGLE_VISIBLE_TECHNIQUES")
	},
	setShowSelectedItems({ commit }, payload) {
		commit("SET_VISIBLE_TECHNIQUES", payload)
	},
	setTechniqueColorScheme({ commit, getters }, { color, refID }) {
		let { selectedItems } = getters
		let payload

		selectedItems[refID].forEach(item => {
			payload = { ...item }
			commit("SET_COLOR_SCHEME", { color, payload, refID })
		})
	},
	resetTechniqueColorScheme({ getters, commit }, { refID }) {
		let { selectedItems } = getters

		selectedItems[refID].forEach(item => {
			let { tid, tnid } = item
			commit("RESET_TECHNIQUE_STYLE", { tid, tnid, refID })
		})
	},
	processRemovableItems({ commit }, removableItems) {
		removableItems.forEach(payload => {
			commit("UNSET_SELECTED_ITEMS", payload)
		})
	},
	toggleSubtechnique({ commit, getters }, { tid, tnid, refID }) {
		let { navigatorData } = getters
		let is_expanded = navigatorData[refID][tid].techniques[tnid]?.is_expanded
		let length = navigatorData[refID][tid].techniques[tnid]?.subtechniques?.length

		if (!length) return
		commit("TOGGLE_SUBTECHNIQUE", { tid, tnid, is_expanded, refID })
	},
	selectSingleTechnique({ state, dispatch }, info) {
		let { tid, tnid, refID, color } = info
		let payload = { tid, tnid }
		let { is_selected_active } = state.navigatorData[refID][tid].techniques[tnid]

		dispatch("getSelectedItemLength", { ...info }).then(len => {
			if (len) return
			dispatch("processSingleTechnique", { refID, payload, is_selected_active })
			// setTechniqueColorScheme
			if (color) dispatch("setCustomTechniqueColorScheme", { color, refID, tid, tnid })
		})
	},
	setCustomTechniqueColorScheme({ commit }, { color, refID, tid, tnid }) {
		// set color scheme directly in navigatorData
		commit("SET_COLOR_SCHEME", { color, payload: { tid, tnid }, refID })
	},
	selectMultipleTechnique({ commit, state }, { tid, tnid, refID }) {
		let payload = { tid, tnid }
		let { is_selected_active } = state.navigatorData[refID][tid].techniques[tnid]

		if (is_selected_active) {
			commit("UNSET_SELECTED_ITEMS", { payload, refID })
			commit("UNSET_SINGLE_TECHNIQUE", { payload, refID })
			return
		}
		commit("SET_SINGLE_TECHNIQUE", { payload, refID })
		commit("SET_SELECTED_ITEMS", { payload, refID })
	},
	processSingleTechnique({ commit, dispatch }, { refID, payload, is_selected_active }) {
		if (is_selected_active) {
			commit("UNSET_SELECTED_ITEMS", { payload, refID })
			commit("UNSET_SINGLE_TECHNIQUE", { payload, refID })
			return
		}

		dispatch("clearSelectedItems", refID).then(() => {
			commit("SET_SINGLE_TECHNIQUE", { payload, refID })
			commit("SET_SELECTED_ITEMS", { payload, refID })
		})
	},
	selectMultipleSubtechnique({ commit, dispatch, state }, { tid, tnid, stnid, refID }) {
		let payload = { tid, tnid, stnid }
		let is_selected = state.navigatorData[refID][tid].techniques[tnid]?.subtechniques[stnid]?.is_selected

		if (is_selected) commit("UNSET_SINGLE_SUBTECHNIQUE", { ...payload, refID })
		else commit("SET_SINGLE_SUBTECHNIQUE", { ...payload, refID })

		dispatch("getSelectedItemLength", { ...payload, refID }).then(len =>
			dispatch("processSelectedTechnique", { payload, len, refID, is_selected })
		)
	},
	clearSelectedItems({ commit, dispatch, getters }, refID) {
		let result = []
		let { selectedItems } = getters
		let len = selectedItems[refID]?.length

		if (!len) return

		Object.values(selectedItems[refID]).forEach(payload => {
			result.push(payload)
			commit("UNSET_SINGLE_TECHNIQUE", { payload, refID })
		})
		dispatch("resetSubtechniqueItems", { refID, result }).then(() => {
			commit("RESET_SELECTED_ITEMS", refID)
		})
	},
	resetSubtechniqueItems({ commit, getters }, { refID, result }) {
		result.forEach(({ tid, tnid, stnid }) => {
			let subtechniques = getters.navigatorData[refID][tid].techniques[tnid].subtechniques
			let subtechnique = subtechniques?.length ? subtechniques[stnid] : null

			if (subtechnique?.is_selected) commit("UNSET_SUBTECHNIQUE_ITEM", { tid, tnid, stnid, refID })
		})
	},
	getSelectedItemLength({ state }, { tid, tnid, refID }) {
		let { subtechniques } = state.navigatorData[refID][tid].techniques[tnid]
		if (!subtechniques) return
		let selectedItems = subtechniques.filter(subtechnique => subtechnique.is_selected === true)

		return selectedItems.length
	},
	processSelectedTechnique({ commit }, { payload, len, refID, is_selected }) {
		if (len) {
			if (!is_selected) commit("SET_SELECTED_ITEMS", { payload, refID })
			commit("SET_SINGLE_TECHNIQUE", { payload, refID })
		}

		if (len === 0) {
			commit("UNSET_SELECTED_ITEMS", { payload, refID })
			commit("UNSET_SINGLE_TECHNIQUE", { payload, refID })
		}
	},
	isParentTechniqueSelected({ dispatch, state }, { tid, tnid, refID }) {
		let { is_selected } = state.navigatorData[refID][tid].techniques[tnid]
		let payload = { tactic_index: tid, technique_index: tnid }
		if (!is_selected) dispatch("selectSingleTechnique", payload)
	},
	resetState({ commit }) {
		commit("RESET_STATE")
	},
	toggleSearchBox({ commit, getters }) {
		let { isSidebarOpen } = getters
		commit("TOGGLE_SEARCH_BOX", !isSidebarOpen)
	},
	resetTabsConfig({ commit }) {
		commit("RESET_TAB_CONFIG")
	},
	getActiveLayerName({ getters }) {
		let { tabConfig, activeTab } = getters;

		return tabConfig[activeTab].title;
	},
	appendTechnique({ commit }, { technique, tactic_index }) {
		commit("APPEND_TECHNIQUE", { technique, tactic_index })
	},

	// Techniques Page
	setSelectedTechniqueInfo({ commit }, payload) {
		commit("SET_SELECTED_TECHNIQUE_INFO", payload)
	},
	setSelectedTechniqueSelectedItems({ commit }, sectionsData) {
		commit("SET_SELECTED_TECHNIQUE_INFO_SELECTED_ITEMS", sectionsData)
	},
	setSelectedTechniqueItem({ commit }, { target, event }) {
		commit("SET_SELECTED_TECHNIQUE_ITEM", { target, event })
	},
	setSelectedTechniqueMode({ commit }, mode) {
		commit("SET_SELECTED_TECHNIQUE_MODE", mode)
	},

	// Authentication
	setSigninState({ commit }, isLoggedIn) {
		localStorage.setItem("isLoggedIn", isLoggedIn)
		commit("SET_SIGNIN_STATE", isLoggedIn)
	},
	signout({ commit }) {
		localStorage.removeItem("isLoggedIn")
		commit("SET_SIGNIN_STATE", false)
		router.push({ name: "Home" })
	},
	setAccessToken({ commit }, accessToken) {
		axios.defaults.headers.common["x-access-token"] = accessToken
		localStorage.setItem("accessToken", accessToken)
		commit("SET_ACCESS_TOKEN", accessToken)
	},
	setSOPs({ commit }, sops) {
		commit("SET_SOPS", sops)
	},
}
