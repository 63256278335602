<template>
	<div class="navigator-body-wrapper">
		<SidebarBase :header-title="getSidebarTitle()">
			<component :is="targetSidebarComponent" :refID="refID" :ref="targetSidebarComponent" />
		</SidebarBase>
		<LayerBase :refID="refID" v-if="isNewTab()" />
		<section class="navigator-body" v-else>
			<div class="navigator-body-item toolbox" v-if="showToolbox">
				<div class="navigator-header-start" v-if="showTitle">
					Robust and Comprehensive TTP-Based Framework on Classified Cybercrimes
				</div>
				<div class="navigator-header-end">
					<!-- Add custom techniques -->
					<!-- <div class="navigator-header-item" @click="toggleAddTechniqueSheet()">
						<div class="show-all is-fit-width">
							<span class="icon">
								<icon icon="plus" />
							</span>
						</div>
					</div> -->
					<div class="navigator-header-item" @click="toggleShareLayerSheet()">
						<div class="show-all is-fit-width">
							<span class="icon">
								<icon icon="arrow-up-from-bracket" />
							</span>
							<!-- <span> Share </span> -->
						</div>
					</div>
					<div class="navigator-header-item" @click="showSOPs()">
						<div class="show-all is-fit-width">
							<span class="icon">
								<icon icon="file-lines" />
							</span>
							SOPs
							<!-- <span> Share </span> -->
						</div>
					</div>
					<div class="navigator-header-item" @click="processToggleSearchBox('SidebarBaseHandles')">
						<div class="show-all is-fit-width">
							<span class="icon">
								<icon icon="plus" />
							</span>
							<span> Handles</span>
						</div>
					</div>
					<div class="navigator-header-item is-highlighted">
						<div class="show-all is-fit-width" @click="toggleShowSelectedItems">
							<span class="mr-1">Show</span>
							<span v-if="!showSelectedItems">selected</span>
							<span v-else>All</span>
						</div>
					</div>
					<div class="navigator-header-item is-highlighted">
						<ColorPicker :refID="refID" />
					</div>
					<div
						class="navigator-header-item is-highlighted"
						@click="processToggleSearchBox('SidebarBaseValidationData')"
					>
						<div class="search-button">
							<icon class="has-text-grey" icon="magnifying-glass" v-if="!isSidebarOpen" />
							<icon class="has-text-grey" icon="times" v-if="isSidebarOpen" />
						</div>
					</div>
				</div>
			</div>
			<div class="navigator-body-item main-tool">
				<div class="box has-background-dark has-text-light">
					<NavigatorTool :refID="refID" />
				</div>
			</div>
		</section>
		<!-- Share layer -->
		<ShareLayerBase :refID="refID" v-if="showShareLayerSheet" @close="toggleShareLayerSheet()" />
		<!-- Custom Technique -->
		<AddCustomTechnique :refID="refID" v-if="showAddTechniquesSheet" @close="toggleAddTechniqueSheet()" />
	</div>
</template>

<script>
import ColorPicker from "@/components/ColorPicker.vue"
import NavigatorTool from "@/components/NavigatorTool.vue"
import LayerBase from "@/components/LayerBase.vue"
import SidebarBaseValidationData from "@/components/SidebarBaseValidationData.vue"
import SidebarBaseHandles from "@/components/SidebarBaseHandles.vue"
import SidebarBase from "@/components/SidebarBase.vue"
import ShareLayerBase from "@/components/ShareLayerBase.vue"
import AddCustomTechnique from "@/components/AddCustomTechnique.vue"

import { mapActions, mapGetters } from "vuex"

export default {
	data() {
		return {
			targetSidebarComponent: null,
			showShareLayerSheet: false,
			showAddTechniquesSheet: false
		}
	},
	props: {
		refID: {
			type: String
		},
		tabDetails: {
			type: [Object, Array]
		},
		showTitle: {
			type: Boolean,
			default: true
		},
		showToolbox: {
			type: Boolean,
			default: true
		}
	},
	components: {
		ColorPicker,
		NavigatorTool,
		LayerBase,
		SidebarBaseValidationData,
		SidebarBaseHandles,
		SidebarBase,
		ShareLayerBase,
		AddCustomTechnique
	},
	methods: {
		...mapActions(["toggleShowSelectedItems", "setNavigatorData", "toggleSearchBox"]),
		setTabFocus() {
			console.log("Set tab active")
		},
		isNewTab() {
			let { navigatorData, tabConfig, activeTab } = this
			if (!navigatorData[tabConfig[activeTab]?.id]) return true
		},
		processToggleSearchBox(componentName) {
			this.targetSidebarComponent = componentName
			this.toggleSearchBox().then(() => {
				if (!this.isSidebarOpen) return
				this.$refs[componentName].focus()
			})
		},
		getSidebarTitle() {
			let { targetSidebarComponent } = this
			let result = ""

			switch (targetSidebarComponent) {
				case "SidebarBaseValidationData":
					result = "Search"
					break
				case "SidebarBaseHandles":
					result = "Manage Handles"
					break

				default:
					result = "Search"
					break
			}

			return result
		},
		toggleShareLayerSheet() {
			this.showShareLayerSheet = !this.showShareLayerSheet
		},
		toggleAddTechniqueSheet() {
			this.showAddTechniquesSheet = !this.showAddTechniquesSheet
		},
		showSOPs() {
			this.$router.push({ name: "SOPsList" })
		}
	},
	computed: {
		...mapGetters(["showSelectedItems", "tabConfig", "activeTab", "navigatorData", "isSidebarOpen"])
	}
}
</script>

<style lang="scss" scoped>
.navigator-body-wrapper {
	display: flex;
	flex-direction: column;

	background-color: $base-background-color;

	width: 100%;
	height: 100%;
	section.navigator-body {
		overflow: hidden;
		display: flex;
		flex-direction: column;

		background-color: $base-background-color;

		width: 100%;
		height: 100%;
	}

	.navigator-body-item {
		&.toolbox {
			height: fit-content;
			width: 100%;
			background-color: $base-background-color-light;
			padding: calc($gap * 1.5);

			display: flex;
			justify-content: space-between;
			align-items: center;

			.navigator-header-start {
				font-weight: 800;
				font-size: 18px;
				color: $base-text-color-light;
			}
		}

		&.main-tool {
			height: 100%;
			background-color: $base-background-color-light;
			overflow: hidden;

			display: flex;
			justify-content: center;
			align-items: center;
			padding: $gap;

			.box {
				position: relative;
				height: 100%;
				width: 100%;
				overflow: auto;
				border-radius: 0 !important;
				box-shadow: none !important;
				background-color: transparent !important;
				padding: 0;
			}
		}

		.navigator-header-end {
			display: flex;
			gap: $gap;
			align-items: center;
			background-color: transparent;

			.navigator-header-item {
				display: flex;
				gap: $gap;

				align-items: center;
				justify-content: center;

				width: fit-content;
				min-width: $navigator-header-height-min;
				height: $navigator-header-height-min;
				border-radius: calc($gap/2);

				color: $base-hover-text-color;
				background-color: $base-hover-background-color-light;
				box-shadow: none !important;

				user-select: none; /* standard syntax */
				-webkit-user-select: none; /* webkit (safari, chrome) browsers */
				-moz-user-select: none; /* mozilla browsers */
				-khtml-user-select: none; /* webkit (konqueror) browsers */
				-ms-user-select: none; /* IE10+ */

				&.is-highlighted {
					z-index: 3;
				}

				&:hover {
					cursor: pointer;
					color: $base-hover-text-color-light;
					background-color: $base-hover-background-color;
				}

				&:focus {
					box-shadow: none;
				}
			}
		}
	}
}

.show-all {
	width: fit-content;
	text-align: center;
	padding: 0 $gap;

	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
