<template>
	<div class="preview-container fade-in-up" v-if="Object.keys(selectedPath).length">
		<div class="preview-head">
			<div class="preview-head-item title">{{ selectedPath.title }}</div>
			<div class="preview-head-item close button is-rounded is-light" @click="previewSelectedPath">
				<span class="icon">
					<icon icon="times" />
				</span>
			</div>
		</div>
		<div class="preview-body">
			<NavigatorBaseBodyItem :ref="tabConfig.id" :refID="tabConfig.id" :tab-details="tabConfig" :show-toolbox="false" />
		</div>
		<div class="preview-foot">
			<div class="preview-foot-item">
				<div class="button is-light" @click="previewSelectedPath(false)">
					<span class="icon">
						<icon icon="times" />
					</span>
					<span>Cancel</span>
				</div>
			</div>
			<div class="preview-foot-item">
				<div class="button is-dark">
					<span class="icon">
						<icon icon="plus" />
					</span>
					<span>Create</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import NavigatorBaseBodyItem from "@/views/navigator/NavigatorBaseBodyItem.vue"

export default {
	props: {
		tabConfig: {
			type: Object,
			default: () => ({})
		}
	},
	components: {
		NavigatorBaseBodyItem
	},
	computed: {
		...mapGetters("welcomeSheet", ["selectedItems", "selectedSuggestion", "selectedPath"])
	},
	methods: {
		...mapActions("welcomeSheet", ["previewSelectedPath"])
	}
}
</script>

<style lang="scss" scoped>
.preview-container {
	position: relative;

	display: flex;
	flex-direction: column;
	// gap: $gap;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background-color: white;
	z-index: 10;

	.preview-head {
		height: $navigator-header-height;
		border-bottom: 1px solid $base-background-color-light;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $base-text-color-light;
		padding: calc($gap);

		.preview-head-item {
			height: 100%;
			max-height: $navigator-header-height-min;

			&.title {
				font-size: 1.1rem;
				font-weight: 600;
				margin-bottom: 0;
				line-height: 2;
				color: $base-text-color-light;
			}

			&.button.close {
				width: $navigator-header-height-min;
			}
		}
	}

	.preview-body {
		flex: 1;
		position: relative;
		overflow: auto;
	}

	.preview-foot {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: $gap;

		height: $navigator-header-height;
		border-top: 1px solid $base-background-color-light;
		// padding-top: $gap;
		padding: 0 calc($gap);

		.preview-foot-item {
			height: 100%;
			max-height: $navigator-header-height-min;
		}
	}
}
</style>
