import { createStore } from "vuex"
import initialState from "@/store/state.js"
import rootActions from "@/store/actions.js"
import rootMutations from "@/store/mutations.js"
import rootGetters from "@/store/getters.js"

// Modules
import api from "@/store/modules/api/index.js"
import authentication from "@/store/modules/authentication/index.js"
import welcomeSheet from "@/store/modules/welcome-sheet/index.js"

export default createStore({
	state: initialState(),
	mutations: rootMutations,
	actions: rootActions,
	getters: rootGetters,
	modules: { api, auth: authentication, welcomeSheet }
})
