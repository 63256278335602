<template>
	<div class="search fade-in-left" v-if="isSidebarOpen">
		<div class="search-header">
			<div class="search-title">{{ headerTitle }}</div>
		</div>
		<div class="search-body">
			<slot />
		</div>
		<!-- <div class="search-footer"></div> -->
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
export default {
	props: {
		headerTitle: {
			type: String,
			required: true
		}
	},
	methods: {
		...mapActions(["toggleSearchBox"])
	},
	computed: {
		...mapGetters(["isSidebarOpen"])
	}
}
</script>

<style lang="scss" scoped>
$sidebar-width: 500px;

.search {
	position: fixed;
	right: 0;
	top: $navigator-header-height;
	height: calc(100% - $navigator-header-height);
	width: $sidebar-width;

	background-color: hsla($background-h, $background-s, $background-l, 0.9);
	border-top: 1px solid $base-hover-background-color;
	border-left: 1px solid $base-hover-background-color;
	box-shadow: 0px 10px 25px 15px hsla(0, 0%, 0%, 0.2);
	backdrop-filter: blur(4px);
	z-index: 3;
	padding: $gap calc($gap * 2);

	display: flex;
	flex-direction: column;
	gap: $gap;

	// Slide animation
	animation: slideIn 180ms cubic-bezier(0.67, 0.25, 0.23, 0.77);

	.search-header {
		display: flex;
		gap: $gap;
		justify-content: space-between;
		align-items: center;
		padding-bottom: calc($gap/2);
		min-height: $navigator-header-height;

		// border-bottom: 1px solid $base-hover-background-color;

		.search-title {
			font-size: 16px;
			font-weight: 800;
			color: $base-hover-text-color-light;
		}

		.close {
			display: flex;
			justify-content: center;
			align-items: center;

			height: $navigator-header-height-min;
			width: $navigator-header-height-min;
			color: $base-hover-text-color-light;

			border-radius: 100px;

			&:hover {
				cursor: pointer;
				color: $base-active-text-color-light;
				background-color: $base-hover-background-color;
			}
		}
	}

	.search-body {
		display: flex;
		flex-direction: column;
		gap: $gap;
		height: 100%;
		overflow: hidden;
	}
}

@keyframes slideIn {
	0% {
		-webkit-right: -$sidebar-width;
		-webkit-opacity: 0.7;
		right: -$sidebar-width;
		opacity: 0.7;
	}

	100% {
		-webkit-right: 0;
		-webkit-opacity: 1;
		right: 0;
		opacity: 1;
	}
}
</style>
