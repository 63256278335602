// import navigatorData from "@/assets/js/navigator-data.js"
import initialState from "@/store/state.js"

export default {
	ADD_NEW_TAB: (state, tabConfig) => state.tabConfig.push(tabConfig),
	CLOSE_TAB: (state, index) => state.tabConfig.splice(index, 1),
	APPEND_TECHNIQUE: (state, { technique, tactic_index }) => { state.navigatorData[tactic_index].techniques.push(technique) },

	// SET
	SET_GLOABAL_NAVIGATOR_DATA: (state, navigatorData) => {
		state.navigatorData = navigatorData
	},
	SET_TABID: (state, { tabID, index }) => {
		state.tabConfig[index].id = tabID
	},
	SET_ACTIVE_TAB: (state, index) => (state.activeTab = index),
	SET_TAB_ACTIVE_STATE: (state, index) => (state.tabConfig[index].active = true),
	SET_NAVIGATOR_DATA: (state, tabID) => {
		let data = JSON.parse(JSON.stringify(state.navigatorData))
		state.navigatorData[tabID] = data
	},
	SET_CUSTOM_NAVIGATOR_DATA: (state, { tabID }) => {
		let data = JSON.parse(JSON.stringify(state.navigatorData))
		state.navigatorData[tabID] = data
	},
	SET_SINGLE_TECHNIQUE: (state, { payload, refID }) => {
		let { tid, tnid } = payload
		state.navigatorData[refID][tid].techniques[tnid].is_selected_active = true
	},
	SET_SINGLE_SUBTECHNIQUE: (state, { tid, tnid, stnid, refID }) => {
		state.navigatorData[refID][tid].techniques[tnid].subtechniques[stnid].is_selected = true
	},
	SET_SELECTED_ITEMS: (state, { payload, refID }) => {
		let len = state.selectedItems[refID]?.length

		if (!len) state.selectedItems[refID] = []
		state.selectedItems[refID].push(payload)
	},
	SET_COLOR_SCHEME: (state, { color, payload, refID }) => {
		let { tid, tnid } = payload
		state.navigatorData[refID][tid].techniques[tnid].style = color
		state.navigatorData[refID][tid].techniques[tnid].is_selected = true
		state.navigatorData[refID][tid].techniques[tnid].is_selected_active = false
	},
	SET_TAB_TITLE: (state, title) => {
		state.tabConfig[state.activeTab].title = title
	},
	SET_PREDEFINED_NAVIGATOR_DATA: (state, { data, tabID }) => {
		state.navigatorData[tabID] = data
	},
	// // Techniques Page
	SET_SELECTED_TECHNIQUE_INFO: (state, result) => {
		state.selectedTechniqueInfo = result
	},
	SET_SELECTED_TECHNIQUE_INFO_SELECTED_ITEMS: (state, payload) => {
		state.selectedTechniqueInfo.sectionsData = payload
	},
	SET_SELECTED_TECHNIQUE_ITEM: (state, payload) => {
		let { target, event } = payload
		state.selectedTechniqueInfo[target] = event
	},
	setNavigatorData(state, data) {
		// Store the data using refID as the key
		console.log(data)
		// state.navigatorData[refID] = navData;
	},
	SET_SELECTED_TECHNIQUE_MODE: (state, mode) => {
		state.isTechniqueEditable = mode
	},
	SET_TAB_LOADING_STATE: (state, { index, isLoading }) => { state.tabConfig[index].isLoading = isLoading },
	SET_VISIBLE_TECHNIQUES: (state, payload) => (state.showSelectedItems = payload),

	// Tokens
	SET_ACCESS_TOKEN: (state, accessToken) => (state.accessToken = accessToken),
	SET_REFRESH_TOKEN: (state, refreshToken) => {
		state.refreshToken = refreshToken
		localStorage.setItem("refreshToken", refreshToken)
	},

	// UNSET
	UNSET_SINGLE_TECHNIQUE: (state, { payload, refID }) => {
		let { tid, tnid } = payload
		state.navigatorData[refID][tid].techniques[tnid].is_selected_active = false
	},
	UNSET_SINGLE_SUBTECHNIQUE: (state, { tid, tnid, stnid, refID }) => {
		let { selectedItems } = state
		let index = selectedItems[refID].findIndex(item => item.tid === tid && item.tnid === tnid && item.stnid === stnid)

		state.selectedItems[refID].splice(index, 1)
		state.navigatorData[refID][tid].techniques[tnid].subtechniques[stnid].is_selected = false
	},
	UNSET_SELECTED_ITEMS: (state, { payload, refID }) => {
		let { selectedItems } = state
		let { tid, tnid } = payload
		let index = selectedItems[refID].findIndex(item => item.tid === tid && item.tnid === tnid)

		state.selectedItems[refID].splice(index, 1)
	},

	//reset techinque


	// RESET
	RESET_SELECTED_ITEMS: (state, refID) => (state.selectedItems[refID] = []),
	RESET_ALL_SELECTED_ITEMS: state => (state.selectedItems = {}),
	RESET_STATE: state => {
		const s = initialState()
		Object.keys(s).forEach(key => (state[key] = s[key]))
	},
	RESET_TECHNIQUE_STYLE: (state, payload) => {
		let { tid, tnid, refID } = payload

		state.navigatorData[refID][tid].techniques[tnid].style = {}
		state.navigatorData[refID][tid].techniques[tnid].is_selected = false
		state.navigatorData[refID][tid].techniques[tnid].is_selected_active = true
	},
	UNSET_SUBTECHNIQUE_ITEM: (state, payload) => {
		let { tid, tnid, stnid, refID } = payload
		state.navigatorData[refID][tid].techniques[tnid].subtechniques[stnid].is_selected = false
	},
	RESET_TAB_CONFIG: (state) => {
		state.tabConfig = []
	},
	RESET_NAVIGATOR_DATA: (state) => {
		state.navigatorData = {}
	},


	// TOGGLE
	TOGGLE_SEARCH_BOX: (state, isSidebarOpen) => (state.isSidebarOpen = isSidebarOpen),
	TOGGLE_VISIBLE_TECHNIQUES: state => (state.showSelectedItems = !state.showSelectedItems),
	TOGGLE_SUBTECHNIQUE: (state, { tid, tnid, is_expanded, refID }) => {
		state.navigatorData[refID][tid].techniques[tnid].is_expanded = !is_expanded
	},

	// AUTHENTICATION
	SET_SIGNIN_STATE: (state, isLoggedIn) => (state.isLoggedIn = isLoggedIn),

	// DELETE
	DELETE_NAVIGATOR_DATA: (state, refID) => delete state.navigatorData[refID],
	DELETE_SELECTED_ITEMS: (state, refID) => delete state.selectedItems[refID],
	SET_SOPS: (state, sops) => (state.sops = sops)
}
